
import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";


// todo add  printdir to every func 
// todo turn axios into fetch get rid of axios 


function printdir(currentloc, dataname, data) {
    //console.log('current loc => ', currentloc, '  dataname -> ', dataname)
    //console.dir(data)
}

const using_urls = {
            account_login          :   '/giris-yap',
            get_settings_external  :   '/settings_b2b_info',
            exchange_control       :   '/kur_kontrol', // 26.11.2022 - Gunluk kur kontrolu yapiliyor
            settings_company_title :   '/giris_firma_bilgileri', // 12.10.2023 - ilk giris firma bilgileri aliniyor
            search                 :   '/ara',
            search_filter_list_url :   '/ara_filtre_listeleri',
            user_product_search    :   '/ara_kullanici_urun', // 16.05.2023 - kullanici urun arama kayit islemleri
            get_categories         :   '/ara_kategoriler', //19.06.2023 - urun arama kategori islemleri
            get_reconciliations    :   '/mutabakat-kontrol', //13.12.2023 - mutabakat musteri bildirim kontrolu
            cust_reconciliation    :   '/musteri_mutabakat', //20.12.2023 - musteri mutabakat islemleri
            cust_reconciliation_ledger_list : '/musteri_mutabakat_hareket_listesi', //22.12.2023 - musteri mutabakat cari hareket listesi
            cartupdate             :   '/sepetim',
            checkedcartupdate      :   '/sepetim_secim_guncelle', //sepetteki isaret guncellemesi
            returncartinfo         :   '/iade_kontrol',
            returncartupdate       :   '/iadesepetim',
            returncartdelete       :   '/iadesepetsil',
            cartupdate_update      :   '/sepetim_guncelle',
            favoriler_add          :   '/favorilere-ekle',
            favorilar_sub          :   '/favorilere-cikar',
            getitemsbyid           :   '/getitemsbyid',
            orders                 :   '/hesabim/siparislerim',
            returns                :   '/hesabim/iadelerim',
            cari_hareket           :   '/cari_hareketler',
            siparislerim           :   '/siparislerim',
            iadelerim              :   '/iadelerim',
            complete_order         :   '/siparis_tmmla',
            complete_order_satis   :   '/siparis_tmmla_satis',
            complete_order_cust    :    '/siparis_tmmla_cust', 
            complete_return        :   '/iade_tmmla',
            make_payment           :   '/siparis_tmmla_kk',
            user_make_payment      :   '/odeme_banka_bilgisi',
            cash_make_payment      :   '/odeme_tmmla_kk',
            cash_make_payment3D    :   '/odeme_tmmla_kk3d',
            cash_make_paymenttest  :    '/odeme_tmmla_kktest',
             cash_make_paymenttest3D :   '/odeme_tmmla_kk3Dtest',
            islem_table            :   '/islem_table',
            invoice_details        :   '/fatura-detay',
            document_addnewpass    :   '/hareket-yeni-sifre', // 24.11.2022  -   Cari hareketlerin sifreli hale getirilmesi

            get_settings_pages     :    '/settings_pages',
            satis_settings_columns :    '/settings_columns',

            system_control         :    '/sistem-kontrol', // 09.03.2023 - Sistem guncellenme kontrolu 

            easy_payment_user_info :   '/kolay_odeme_musteri_bilgisi',
            easy_payment_pay_info  :   '/kredi_karti_odeme_bilgisi',

            satis_islem_listesi    : '/satis_get_islemler',

            satis_login                 :   '/satis_login',
            search_customers            :   '/satis_search_customer',
            satis_search_item           :   '/satis_search_item',

            satis_get_cart              :   '/satis_get_cart',
            satis_get_cust_cart         :   '/satis_get_cust_cart',

            satis_add_to_cart           :   '/satis_add_to_cart',
            satis_add_to_cust_cart      :   '/satis_add_to_cust_cart',

            satis_get_cust_cari         :   '/satis_get_cust_cari',
            satis_user_new_pass         :   '/satis_user_new_pass',
            satis_cust_new_pass_control :   '/satis_cust_new_pass_control',
            satis_cust_privinsta        :   '/satis_ozel_taksit_islem', // 24.01.2023 - ozel taksit islemleri
            satis_cust_collection_save  :   '/satis_tahsilat_kayit', // 06.12.2023 - tahsilat kayit
            satis_cust_collection_list  :   '/satis_tahsilatListesi',
            satis_user_actions          :   '/satis_kullanici_islemleri', //28.03.2023 - kullanici islemleri
            satis_product_actions       :   '/satis_urun_islemleri', //15.05.2023 - urun islemleri
            satis_product_info          :   '/satis_urun_bilgisi', //31.05.2023 - urun islemleri - urun bilgisi
            satis_company_actions       :   '/satis_firma_islemleri', //11.07.2023 - firma islemleri
            satis_company_info          :   '/satis_firma_bilgisi', //11.07.2023 - firma islemleri - firma bilgisi
            satis_setting_actions       :   '/satis_genel_ayarlar', //29.05.2023 - genel sistem ayarlari

            ayarlar_siparis_listesi     :   '/ayarlar_siparis_listesi',
            ayarlar_slayt_islemleri     :   '/ayarlar_slayt_islemleri',
            ayarlar_siparis_aktarim     :   '/ayarlar_siparis_aktarim',
            ayarlar_kullanici_listesi   :   '/ayarlar_kullanici_listesi', //15.05.2023 - tum urun listesi
            ayarlar_urun_listesi        :   '/ayarlar_urun_listesi',
            ayarlar_firma_listesi       :   '/ayarlar_firma_listesi', //17.07.2023 - tum firma listesi
            ayarlar_mutabakat_listesi   :   '/ayarlar_mutabakat_listesi', //07.12.2023 - tum mutabakat listesi
            ayarlar_mutabakat_liste_detay:  '/ayarlar_mutabakat_liste_detay', //07.12.2023 - mutabakat liste detaylari
            ayarlar_mutabakat_islemleri :   '/ayarlar_mutabakat_islemleri', //11.12.2023 - mutabakat islemleri
            ayarlar_islem_listesi       :   '/ayarlar_islem_listesi', //07.04.2023 - tum kredi karti islem listesi
            ayarlar_odeme_guncelle      :   '/ayarlar_odeme_guncelle', //06.05.2023 - kredi karti islem kaydi guncelleme
            ayarlar_iade_listesi        :   '/ayarlar_iade_listesi', //07.04.2023 - iade listesi
            ayarlar_arama_gecmisi       :   '/ayarlar_arama_gecmisi', //18.10.2023 - arama geçmişi
            ayarlar_tahsilat_listesi    :   '/ayarlar_tahsilat_listesi', 


            get_fast_cari               :   '/fast_cari',

}

export default createStore({
    //                 GETTERS
   getters: {
        search_string(state){
            const page = 'search'
            if( page in state.loadedpages && 'reqsent' in state.loadedpages[page] ){
                return state.loadedpages[page]['reqsent']['search_string'] || ""
            } 
        },
        
        getNewData( state ) {
            return state.new_data
        },
        getItemsMenu( state ) {
            return state.menu_itemsv
        },
        get_focus_item( state ) {
            return state.app_statemanage.focused_item
        },
        get_account(state){
            return state.accountinfo
        },
        getitemsinfo(state){
            return state.account.initial_items
        },
        search_result(state){
            const page = 'search'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] ){
                return state.loadedpages[page]['data'] 
            }
        },
        get_account_cart(state){
            return state.account_cart || {}
        },
        get_account_cart_items(state) {
            return state.account_cart.items || []
        },
        get_arsal_vendor_info(state) {
            return state.arsal_vendor_info || []
        },
        get_account_return_cart_items(state) {
            // console.log("state.account_return_cart.accountid :" + JSON.stringify(state.account_return_cart.accountid ))
            // console.log("state.accountinfo.id:" + JSON.stringify(state.accountinfo.id))

            if (state.account_return_cart == undefined){
                return []
            }
            if (state.account_return_cart.accountid != state.accountinfo.id){
                return []
            }
            return state.account_return_cart.items || []
        },
        get_account_addresses( state ) {
            return {
                    billing : state.accountinfo.billing_addresses[0],
                    delivery: state.accountinfo.delivery_addresses[0]
                    }
        },
        get_detailedfavorites(state){
            return state.detailedfavorites
        },
        get_past_orders(state) {
            const page = 'orders'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] )
            {
                const retthis = state.loadedpages[page]['data'] 
                return retthis
            }        
        },
        get_pop_items(state){
            return state.slider['pop']
        },
        get_new_items(state){
            return state.slider['new'] || []
        },
        get_bank_list(state){
            return state['bank_pos_info']
        },
        get_loginsuccess(state){

            // console.log("system_control:" + state.system_control)

            // B 20.11.2022 - Gunluk giris tarih kontrolu 
            var lastloginstatus = false;
            if (state['loginsuccess'] == true){
                const controlToday  = new Date();
                const controlDate   = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
                if (state['accountinfo'].lastlogindate != undefined){
                    const UserDate      = new Date(state['accountinfo'].lastlogindate);
                    const controlUserDate   = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
                    if (Number(controlDate) == Number(controlUserDate)){
                        lastloginstatus = true;
                    }
                }
            }
            // E 20.11.2022 - Gunluk giris tarih kontrolu

            // console.log("system_statusx:" + system_status)
            // console.log("state['accountinfo'].system_statusx:" + state['accountinfo'].system_status)
            // console.log("lastloginstatus:" + lastloginstatus)
            
            return lastloginstatus
            //return state['loginsuccess']
        },


        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu
        get_satisloginsuccess(state){

            var lastloginstatus = false;
            if (state['satisloginsuccess'] == true){
                const controlToday  = new Date();
                const controlDate   = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
                if (state['satis_account'] != undefined){
                    if (state['satis_account'].lastlogindate != undefined){
                        // console.log("lastlogindate-satis_account: " + state['satis_account'].lastlogindate)
                        const UserDate      = new Date(state['satis_account'].lastlogindate);
                        const controlUserDate   = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
                        if (Number(controlDate) == Number(controlUserDate)){
                            lastloginstatus = true;
                        }
                    }
                }
            }

            return lastloginstatus
            //return state['satisloginsuccess']
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu


        get_settings(state)
        {
            return state['settings']
        },
        get_salesperson(state)
        {
            return state['salesperson']
        },
        get_user_searchpool(state)
        {
            return state['user_searchpool']
        },
        get_exchanges(state)
        {   

            const exchange_rates = state.settings.used_exchange_rates 
            const user_rates     = state.settings.exchange_USER
            const tcmb_rates     = state.settings.exchange_TCMB

            var current_date = getDate_yyyymmdd()

            if (tcmb_rates == undefined){ //03.12.2022 - tcmb_rates degeri yok ise islem yapiliyor
                return {}
            }

            const get_last_tcmb_rates = tcmb_rates.slice(-1); //en son alinan tcmb kur bilgisi
            const tcmb_current_last_date = get_last_tcmb_rates[0].tarih;

            if (current_date != tcmb_current_last_date) { //gunun tarihi ile son tcmb kur tarihi farkli ise tcmb kur tarihi alinacak - ozellikle resmi tatillerden sonra tatil tarihli tcmb kuru olmuyor
                current_date = tcmb_current_last_date;
            }

            const get_used_rate = exchange_rates.map(
                i => {
                    if( i.senkron )
                    {
                        var retthis = tcmb_rates.filter(
                            j => {
                                return (j.kurismi == i.kurismi) && (j.tarih == current_date)
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                    } else 
                    {
                        var retthis = user_rates.filter(
                            j => {
                                return j.kurismi == i.kurismi 
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                    }
                }
            )

            return get_used_rate

        },
        get_exchanges_tcmb(state)
        {   

            const exchange_rates = state.settings.used_exchange_rates 
            const tcmb_rates     = state.settings.exchange_TCMB

            var current_date = getDate_yyyymmdd()

            if (tcmb_rates == undefined){ //03.12.2022 - tcmb_rates degeri yok ise islem yapiliyor
                return {}
            }

            const get_last_tcmb_rates = tcmb_rates.slice(-1); //en son alinan tcmb kur bilgisi
            const tcmb_current_last_date = get_last_tcmb_rates[0].tarih;

            if (current_date != tcmb_current_last_date) { //gunun tarihi ile son tcmb kur tarihi farkli ise tcmb kur tarihi alinacak - ozellikle resmi tatillerden sonra tatil tarihli tcmb kuru olmuyor
                current_date = tcmb_current_last_date;
            }

            const get_used_rate = exchange_rates.map(
                i => {

                        var retthis = tcmb_rates.filter(
                            j => {
                                return (j.kurismi == i.kurismi) && (j.tarih == current_date)
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                }
            )

            return get_used_rate

        },
        get_cari_lines(state)
        {
            return state.cari_lines
        },
        get_cari_entry(state)
        {

            const page = 'cari_hareket'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] )
            {
                const retthis = state.loadedpages[page]['data'] 
                return retthis
            } 
        },
        get_dash_active_table(state)
        {
            return state.dash_active_table
        },
        get_satis_dash_active_table(state)
        {
            return state.satis_dash_active_table
        },
        get_total_cari(state)
        {
            return state['account_total_cari']
        },
        get_cari_vade(state)
        {
            return state['account_cari_vade']
        },
        get_outofdate_total(state)
        {
            return state['outofdate_total']
        },
        get_outofdate_total_info(state)
        {
            return state['outofdate_total_info']
        },
        get_siparislerim(state)
        {
            return state.loadedpages['siparislerim']['data']
        },
        get_iadelerim(state)
        {
            return state.loadedpages['iadelerim']['data']
        },
        get_account_item_details(state)
        {
            return state['account_item_details']
        },
        get_order_no(state)
        {
            return state['order_no']
        },
        get_payment_installment(state)
        {
            return state['payment_installment']
        },
        get_islem_table(state)
        {
            return state.loadedpages['islem_table']['data']
        },
        get_satis_account(state)
        {
            return state.satis_account
        },
        get_customer_search(state)
        {
            return state['search_customer']
        },
        get_selected_company(state)
        {
            return state['selected_company']
        },
        get_satis_item_searched(state)
        {
            const page = 'satis_search_item'

            var doesloaded_pages = 'loadedpages' in state
            if(!doesloaded_pages) state['loadedpages'] = {}
            
            var doesloadedexist = page in state.loadedpages
            if( !doesloadedexist ) 
            {
                state.loadedpages[page] = {}
            };
            
            if( page in state.loadedpages && 'data' in state.loadedpages[page] )
            {
                const retthis = state.loadedpages[page]['data'] 
                return retthis
            } 
        },
        get_satis_cust_cart(state){
            return state['satis_cust_cart']
        },
        
        get_satis_cart(state)
        {
            return state['satis_cart']
        },

        get_satis_cust_cari(state)
        {
            const page = 'satis_get_cust_cari'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] )
            {
                const retthis = state.loadedpages[page]['data'] 
                return retthis
            }  
        },

        get_cust_cari_vade(state)
        {   
            return state['satis_cust_cari_vade']
        },
        get_cust_sum_total_cari(state)
        {
            return state['satis_cust_cari_total']
        },

        
        get_cust_sum_total_debt(state)
        {
            return state['satis_total_debt']
        },

        get_cust_sum_total_credit(state)
        {
            return state['satis_total_credit']
        },

        get_satis_islem_listesi(state)
        {


            const page = 'satis_islem_listesi'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] )
            {
                const retthis = state.loadedpages[page]['data'] 
                return retthis
            }  
        },

        get_store_size(state){
            const state_size = JSON.stringify(state).length * 2
            console.log('current state size '  + state_size/1024 + ' kb')

            const all_state_keys = Object.keys(state)

            all_state_keys.map(
                i=> {
                    const l_state_size = JSON.stringify(state[i]).length * 2
                    console.log(i + ' ' + l_state_size/1024 + ' kb')
                    const one_more_key  = Object.keys(state[i])
                    one_more_key.map(
                        j => {
                            const ll_state_size = JSON.stringify(state[i][j]).length * 2
                            console.log("   - " + j + " " + ll_state_size/1024 + ' kb')
                        }
                    )
                }
            )

            return state_size
        }
        
    },
    //                  MUTATIONS 
    mutations: {

        reset_state(state,payload)
        {
            // store.replaceState({})
            // this.reset()
            // store.reset()
            // state.reset()

            const keys_array = Object.keys(state)

            keys_array.map(
                i=> {
                    state[i]={}
                }
            )
            Object.assign(state, {})

            // console.dir(state)
        },
        //============= LOGIN INITILIZATION =============
        //
        //
        //
        //===============================================
        toolBar(state, val){
            document.title = val.pageTitle
            state.toolBar = val
        },
        login_initialization(state, payload){
                
               // state.account.name  = payload.name
               // state.account.passw = payload.passw
                state.accountinfo = payload

        },
        //============= SEARCH RESULTS GETING ===========
        //                  INITIAL
        //
        //
        //===============================================
        search_result_save( state, payload) {
            state.search_result = payload
        },
        //============= SEARCH RESULTS GETING ===========
        //                  PUSHING
        //
        //
        //===============================================
        search_result_push( state, payload) {
            state.search_result = payload
        },
        //========= UPDATIN CART AFTER ADD-SUB  ========
        //
        //  payload situation: /push to list
        //                     /splice from list
        //                     /change amount
        //===============================================
        updatecart(state, payload){
            state.account_cart = payload || {}
        },
        updatecart_satis(state, payload){
            state.satis_cart = payload || {}
        },
        updatecart_cust(state, payload){
            state.satis_cust_cart = payload || {}
        },
        updatereturncart(state, payload){
            state.account_return_cart = payload || {}
        },
        //============= LOADING MORE RECORDS TO PAGE ====
        //
        //
        //                  payload = {
        //                            data:
        //                            page: 
        //                            
        //                            }
        //
        //===============================================
        // const commitreq = {
        //     reqsent             : reqsend,
        //     data                : res.data.data,
        //     isonlyload          : isonlyload,
        //     page                : page,
        //     startofresultreturn : startofresultreturn + numofreturn
        // }
        loadmorepagesmut( state, payload ) {



            var doesloaded_pages = 'loadedpages' in state
            if(!doesloaded_pages) state['loadedpages'] = {}
            
            var doesloadedexist = payload.page in state.loadedpages
            if( !doesloadedexist ) 
            {
                state.loadedpages[payload.page] = {}
            };


            if (payload.isonlyload) 
            {
                state.loadedpages[payload.page]['data'] = state.loadedpages[payload.page]['data'].concat( payload.data )
            }
            else {
                state.loadedpages[payload.page]['data'] = []
                Object.assign(state.loadedpages[payload.page]['data'], payload.data)
            }
            state.loadedpages[payload.page]['reqsent']             =  payload.reqsent
            state.loadedpages[payload.page]['isopen']              =  payload.isopen
            state.loadedpages[payload.page]['startofresultreturn'] =  state.loadedpages[payload.page]['data'].length + 1
        },
        //============== GETTING PAST ORDERS ===========
        //
        //
        //
        //
        //=============================================
        save_past_orders(state, payload) {
            state.past_orders = payload
        },
        
        erase_bank_pos_list_from_settings(state)
        {
            state.settings.bank_pos_info = {}
            Object.assign(state.settings.bank_pos_info,{})
        },
        //================ GETTING DETAILED FAVORITES ====
        //
        //
        //
        //===============================================
        save_detailed_favorites(state, payload){
            state.detailedfavorites = payload
        },
        update_cart_change_payload(state, payload)
        {
            state.cart_change_payload = payload
        },
        save_sliders_data(state, payload){
            if( !state['slider'] ) state['slider'] = {};
            state.slider[payload.slider] = payload.data
        },
        save_bank_pos_info(state, payload){
            state['bank_pos_info'] = payload
        },
        save_domain_info( state, payload)
        {
            state['domain'] = payload
        },
        save_arsal_vendor_info( state, payload)
        {
            state['arsal_vendor_info'] = payload
        },
        save_loginsuccess( state, payload)
        {
            state['loginsuccess'] = payload
        },
        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        save_satisloginsuccess( state, payload)
        {
            state['satisloginsuccess'] = payload
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        // B 24.11.2022  -   Cari hareketlerin sifreli hale getirilmesi
        save_document_new_pass(state, payload)
        {
            state['accountinfo']['list_pass'] = payload
        },
        // E 24.11.2022  -   Cari hareketlerin sifreli hale getirilmesi
        save_settings( state, payload)
        {
            state['settings'] = payload
        },
        save_salesperson( state, payload)
        {
            state['salesperson'] = payload
        },
        save_user_searchpool( state, payload)
        {
            state['user_searchpool'] = payload
        },
        save_outofdate_total( state, payload)
        {
            state['outofdate_total'] = payload
        },
        save_outofdate_total_info( state, payload)
        {
            state['outofdate_total_info'] = payload
        },
        save_dash_active_table(state, payload)
        {
            state['dash_active_table'] = payload
        },
        save_satis_dash_active_table(state, payload)
        {
            state['satis_dash_active_table'] = payload
        },
        save_favorites(state, payload)
        {
            state['accountinfo']['favorite_products'] = payload
        },
        save_cari_entry(state, payload)
        {
            state['cari_entry'] = payload
        },
        save_cari_lines(state,payload)
        {
            state['cari_lines'] = payload
        },
        concat_cari_entry(state,payload)
        {
            state['cari_entry'].concat(payload)
        },
        concat_cari_lines(state,payload)
        {
            state['cari_lines'].concat(payload)
        },
        save_account_cart_item_detail(state,payload)
        {
            
            state['account_item_details'] = payload
        
        },
        save_order_no(state, payload){
            state['order_no'] = payload
        },
        save_payment_installment(state, payload){
            state['payment_installment'] = payload
        },
        save_satis_account(state,payload)
        {
            state['satis_account'] = payload
        },
        save_customer_search(state, payload){
            state['search_customer'] = payload
        },
        save_selected_company(state, payload)
        {
            state['selected_company'] = payload
        },
        save_satis_cust_cart(state, payload)
        {
            state['satis_cust_cart'] = payload
        },
        save_satis_cart(state,payload)
        {
            state['satis_cart'] = payload
        },
        save_reset_selected_company(state)
        {
            state['satis_cart'] = {}
            state['satis_cust_cart'] = {}
            state['selected_company'] = {}
            state['search_customer'] = {}
        },

        save_cust_cari_vade(state, payload)
        {   
            state['satis_cust_cari_vade']= payload
        },
        save_cust_cari_total(state,payload)
        {
            state['satis_cust_cari_total'] = payload
        },

        save_fast_cari(state, payload)
        {
            state['account_total_cari'] =   payload.total_cari
            state['account_cari_vade']  =   payload.cari_vade
            state['account_total_debt'] = payload.total_debt
            state['account_total_credit'] = payload.total_credit
        },
        save_satis_fast_cari(state, payload)
        {
            state['satis_cust_cari_vade']  = payload.cari_vade
            state['satis_cust_cari_total'] = payload.total_cari
            state['satis_total_debt'] = payload.total_debt
            state['satis_total_credit'] = payload.total_credit


        },

        save_settings_pages(state, payload)
        {
            state['settings']['pages'] = payload.data
        },

        save_settings_side_sliders(state, payload){
            state['settings']['side_sliders'] = payload
        },

        save_settings_exchange(state, payload)
        {
            state['settings']['used_exchange_rates']    = payload.used_exchange_rates
            state['settings']['exchange_USER']          = payload.exchange_USER
        },
        save_settings_discount_group(state, payload){
            state['settings']['item_discount_groups'] = payload.item_discount_groups
        },
        save_settings_sliders(state, payload){
            state['settings']['sliders'] = payload
        },
        save_settings_columns(state, payload){
            state['settings']['columns'][payload.page] = payload.newColumnsSort
        }
        
    },
    //                  ACTIONS 
    actions: 
    {
        //=============== LOGIN POST FETCH ======================
        //  PAYLOAD : 
        // { acc_email: 
        //   acc_pass:       
        //  }

        // posble impovement - 
        // ADD SETTINGS HASH TO CHECK IF ANYTHING CHANGED ONLY SEND SETTING IF THERE IS A CHANGE 
        //
        //================================================

        async system_control({ state }){ // B 09.03.2023 - Sistem guncellenme kontrolu 

            const reqsend = {
                domain  : state.domain,
            }
            var system_status_info = false
            var system_status = ""

            if (state['accountinfo'].system_status != undefined){
                await fetch( using_urls.system_control, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    system_status = data.system_status
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

                if (state['accountinfo'].system_status == system_status){
                    system_status_info = true
                }
            }

            return system_status_info
        }, // E 09.03.2023 - Sistem guncellenme kontrolu 


        async exchange_control( {commit, dispatch}, payload) { // B 26.11.2022 - Gunluk kur kontrolu yapiliyor

            await fetch( using_urls.exchange_control, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                })
                .then(response => response.json())
                .then(data => {

                    //console.log("today_exchange_length:" + data.today_exchange_length)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }, // E 26.11.2022 - Gunluk kur kontrolu yapiliyor

        async settings_company_title( {commit, dispatch}, payload) { // B 12.10.2023 - ilk giris firma bilgileri aliniyor

            var retthis = "B2B Sistemimize Hoş Geldiniz"

            await fetch( using_urls.settings_company_title, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                })
                .then(response => response.json())
                .then(data => {
                    retthis = data.company_title
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

                return retthis
        }, // E 12.10.2023 - ilk giris firma bilgileri aliniyor
        
        async testing_login_info({commit, dispatch}, payload) {
            var retthis = false
            await fetch( using_urls.account_login, {
                    method: 'POST', // or 'PUT' TAHA BURAYA DOKUN
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    })
                    .then(response => response.json())
                    .then(data => {

                        if (data.loginsuccess){
                            commit('login_initialization'  , data.account_info)

                            dispatch('getPopProducts'      , {
                                domain : data.domain_info,
                                account_rules: data.account_info.rules,
                            })
                            dispatch('getNewProducts'      , {
                                domain : data.domain_info,
                                account_rules: data.account_info.rules,
                            })

                            dispatch('commit_cartinfo'        , data.account_cart)
                            dispatch('commit_returncartinfo'  , data.account_return_cart)
                            dispatch('commit_bank_pos_info'   , data.site_settings.bank_pos_list)
                            dispatch('commit_domain_info'     , data.domain_info)
                            dispatch('commit_loginsuccess'    , data.loginsuccess)
                            dispatch('commit_settings_data'   , data.site_settings)
                            dispatch('commit_salesperson_data', data.sales_person)
                            dispatch('commit_user_searchpool' , data.user_searchpool)
                            dispatch('commit_outofdate_total' , data.outofdate_total)
                            dispatch('commit_outofdate_total_info' , data.outofdate_total)
                            dispatch('commit_arsal_vendor_info' , data.arsal_vendor_info) //20.12.2023 - arsal urun entegrasyon durumu icin eklendi
                            

                            // dispatch('act_erase_bank_pos_list_from_settings')
                            
                            // console.dir(data.account_info)
                            // console.dir(data.account_info.aydin_conf)
                            
                            if(!data.account_info.aydin_conf) 
                            {
                                // console.log('gettings extra pages rn ')
                                dispatch('act_get_pages',{});

                            }   
                        }
 
                        retthis = data.loginsuccess

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            return retthis

        },

        // B 17.11.2022 guncel tags bilgileri aliniyor
        async testing_login_new_info({commit, dispatch}, payload) {
            var retthis = false
            await fetch( using_urls.account_login, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    })
                    .then(response => response.json())
                    .then(data => {

                            commit('login_initialization'  , data.account_info)
                            //console.log("data.account_info:" + data.account_info.tags)
                            retthis = data.loginsuccess
                    })
                    .catch((error) => {
                    console.error('Error:', error);
                    });
            return retthis
        },
        // E 17.11.2022 guncel tags bilgileri aliniyor

        //B 16.05.2023 - kullanici urun arama kayit islemleri
        async user_product_searchpool({ state, dispatch }, payload) {

            var retthis = {}

            const reqsend = {
                account_no  : (payload.user_type == 'customer') ? state.accountinfo.no : state.satis_account.user, // musteri arama sonuclari icin gonderiliyor - sales/customer
                domain      : state.domain
            }

            await fetch( using_urls.user_product_search, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        dispatch('commit_user_searchpool' , data.user_searchpool)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },
        //E 16.05.2023 - kullanici urun arama kayit islemleri

        //B 19.06.2023 - urun arama kategori islemleri
        async get_categories({ state }, payload) {

            var retthis = []

            const reqsend = {
                domain : state.domain
            }

            await fetch( using_urls.get_categories, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.categories
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },
        //E 19.06.2023 - urun arama kategori islemleri

        async get_reconciliations_info({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain               : (state.domain == undefined) ? "" : state.domain,
                accountno            : (state.accountinfo == undefined) ? "" : state.accountinfo.no,
                reconciliation_token : payload.reconciliation_token || "", //e-posta donusu icin kullaniliyor
            }

            // console.log("reqsend:" + JSON.stringify(reqsend))

            await fetch( using_urls.get_reconciliations, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_reconciliations
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_settings_external({ dispatch }, payload) {

            var retthis = {}

            const reqsend = {}

            await fetch( using_urls.get_settings_external, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data
                        dispatch('commit_settings_data'   , data.site_settings)
                        dispatch('commit_domain_info'     , data.domain_info)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_siparis_listesi({ state }, payload) {

            var retthis = {}

            const reqsend = {
                satis_cust_no   : payload.satis_cust_no,
                satis_date_start: payload.satis_date_start,
                satis_date_end  : payload.satis_date_end,
                domain          : state.domain
            }
            await fetch( using_urls.ayarlar_siparis_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.orders
                        // console.log("orders:" + JSON.stringify(retthis))
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_iade_listesi({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_iade_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_return
                        // console.log("orders:" + JSON.stringify(retthis))
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_admin_searchpool_list({ state }, payload) {

            var retthis = {}

            const reqsend = {
                satis_cust_no       : payload.satis_cust_no,
                satis_date_start    : payload.satis_date_start,
                satis_date_end      : payload.satis_date_end,
                satis_search_status : payload.satis_search_status,
                domain              : state.domain
            }
            await fetch( using_urls.ayarlar_arama_gecmisi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.admin_searchpool
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_appusers({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_kullanici_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_users
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_products({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_urun_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_products
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_companies({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_firma_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_companies
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },


        async get_satis_collection({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_tahsilat_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_collections
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },
        
        async get_satis_reconciliations({ state }, payload) {

            var retthis = {}

            const reqsend = {
                search_status   : payload.search_status,
                domain          : state.domain
            }

            await fetch( using_urls.ayarlar_mutabakat_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_reconciliations
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_reconciliation_detail({ state }, payload) {

            var retthis = {}

            const reqsend = {
                search_status_detail    : payload.search_status_detail,
                reconciliation_id       : payload.reconciliation_id,
                domain                  : state.domain
            }

            await fetch( using_urls.ayarlar_mutabakat_liste_detay, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_reconciliations
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async satis_reconciliation_actions({state}, payload){

            const reqsend = {
                reconciliation_id           : payload.reconciliation_id,
                reconciliation_description  : payload.reconciliation_description,
                reconciliation_startingDate : payload.reconciliation_startingDate,
                reconciliation_endingDate   : payload.reconciliation_endingDate,
                reconciliation_dueDate      : payload.reconciliation_dueDate,
                reconciliation_sti_auditor  : payload.reconciliation_sti_auditor,
                reconciliation_auditor_name : payload.reconciliation_auditor_name,
                reconciliation_auditor_email: payload.reconciliation_auditor_email,
                action_type                 : payload.action_type,
                domain                      : state.domain,
            }

            var return_data = {}

            await fetch(using_urls.ayarlar_mutabakat_islemleri, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        
        async cust_reconciliation_actions({state}, payload){

            const reqsend = {
                reconciliation_id   : payload.reconciliation_id,
                customerAmount      : payload.customerAmount,
                customerDebtCredit  : payload.customerDebtCredit,
                action_type         : payload.action_type,
                approverNameSurname : payload.approverNameSurname,
                approverNotes       : payload.approverNotes,
                domain              : state.domain,
                accountno           : payload.cust_no,
                accessType          : payload.accessType,
            }

            var return_data = {}

            await fetch(using_urls.cust_reconciliation, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async cust_reconciliation_ledger_list({state}, payload){

            const reqsend = {
                accountno           : payload.cust_no,
                endingDate          : payload.endingDate,
                domain              : state.domain,
            }

            var return_data = {}

            await fetch(using_urls.cust_reconciliation_ledger_list, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data.cust_ledger_list
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async get_satis_admin_islem_listesi({ state }, payload){

            var retthis = {}

            const reqsend = {
                satis_cust_no   : payload.satis_cust_no,
                satis_date_start: payload.satis_date_start,
                satis_date_end  : payload.satis_date_end,
                domain          : state.domain
            }
            await fetch( using_urls.ayarlar_islem_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_trans
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis

        },

        async satis_transaction_detail_action({ state}, payload){
            var detail_status = {};

            const reqsend = {
                satis_date_start    : payload.payment_filter_date_start,
                satis_date_end      : payload.payment_filter_date_end,
                payment_id          : payload.payment_id,
                payment_status      : payload.payment_status,
                payment_canceled    : payload.payment_canceled,
                payment_provision_no: payload.payment_provision_no,
                payment_installment : payload.payment_installment,
                domain              : state.domain,
            }

            await fetch( using_urls.ayarlar_odeme_guncelle, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    detail_status = data.ret_data
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            return detail_status
        },

        async order_transfer({state}, payload){

            var transfer_status = {};

            const reqsend = {
                order_no    : payload.order_no,
                domain      : state.domain,
            }

            await fetch( using_urls.ayarlar_siparis_aktarim, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    transfer_status = data
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            return transfer_status
        },

        async satis_login( {commit, dispatch}, payload) {
            await fetch( using_urls.satis_login , {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    })
                    .then(response => response.json())
                    .then(data => {
                        dispatch('commit_reset_selected_company')
                        commit('save_satis_account', data.data)
                        dispatch('commit_satisloginsuccess', data.login_sucess) // 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
                        dispatch('commit_settings_data', data.site_settings)
                        dispatch('commit_bank_pos_info', data.site_settings.bank_pos_list)
                        dispatch('commit_domain_info', data.data.domain) //12.04.2023 - domain bilgisi alinamadigindan eklendi
                        dispatch('commit_user_searchpool' , data.user_searchpool) //16.05.2023 - kullanici urun arama kayit islemleri
                        dispatch('commit_arsal_vendor_info' , data.arsal_vendor_info) //20.12.2023 - arsal urun entegrasyon durumu icin eklendi
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
        },

        async search_filter_lists( {commit, dispatch}, payload) {

            await fetch( using_urls.search_filter_list_url, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    })
                    .then(response => response.json())
                    .then(data => {
                        return data
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
        },

        async post_changes_to_db({state, commit, dispatch}, payload) {

            const reqsend = {
                update           : state.cart_change_payload,
                accountId        : state.accountinfo.account_info._id,
                cust_name        : state.accountinfo.account_info.name,
                cust_no          : state.accountinfo.account_info.no,
                sales_person_code: state.accountinfo.account_info.salespersoncode,
                cust_email       : state.accountinfo.account_info.email,
                domain           : state.domain
            }

            await fetch( using_urls.cartupdate_update, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    const newcart = data.account_cart || [];
                    dispatch('commit_cartchanges',{})
                    commit("updatecart", newcart)
                    //dispatch('req_detailed_faves')
                })
                .catch( error => console.error('Error:', error));

            /*
            await axios.post( using_urls.cartupdate_update, reqsend )
                .then(
                    res => {
                        if (res.data.message === 'Sepetiniz başarıyla güncellenmiştir') {
                            const newcart = res.data.account_cart || [];
                            dispatch('commit_cartchanges',{})
                            commit("updatecart", newcart)
    
                            return 
                        } else {
                            console.log(" UPDATE CART  NODE WRONG MESAGE")
                        }
                    }
                )
                .catch (
                    err => console.log(err)
                )

                */

        },
        //============= CARTSELCETION CHANGE==============
        //
        //
        //              
        //================================================

        // payload = {

        //     paymentmethod :
        //     paymentinstallment:
        //     mainposcode:
        //     paymentnavbankcode:
        // }S
        async cartselectionchange( {commit, state, dispatch}, payload) {

            var current_cart = state.account_cart || state.satis_cust_cart || state.cust_cart
            var cart_change_payload = state.cart_change_payload || {}
            console.log("---",current_cart)
            console.log(payload)
            console.log(cart_change_payload)
            //console.dir(payload)

            const cart_keys = Object.keys(current_cart)
            const payloadkeys = Object.keys(payload)
                                        .map(
                                        i => 
                                        {
                                                if(cart_keys.indexOf(i) > -1)
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                }
                                                else
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                    //console.log(i, 'is not a key of cart')
                                                }
                                        }
                                        )
            //console.log('cart_selectionchange  ---> ')

            //console.dir(cart_change_payload)
            
            
            // saving current cart to update UI elements 
            // dispatch('commit_cartinfo',current_cart)
            // saving changes to the cart in order to send changes to mongodb
            commit('update_cart_change_payload', cart_change_payload)

            //commit('updatecart',current_cart)
        
        },
        async cartselectionchange_satis( {commit, state, dispatch}, payload) {

            var current_cart = state.satis_cart 
            var cart_change_payload = state.cart_change_payload || {}
            console.log("---",current_cart)
            console.log(payload)
            console.log(cart_change_payload)
            //console.dir(payload)

            const cart_keys = Object.keys(current_cart)
            const payloadkeys = Object.keys(payload)
                                        .map(
                                        i => 
                                        {
                                                if(cart_keys.indexOf(i) > -1)
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                }
                                                else
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                    //console.log(i, 'is not a key of cart')
                                                }
                                        }
                                        )
            //console.log('cart_selectionchange  ---> ')

            //console.dir(cart_change_payload)
            
            
            // saving current cart to update UI elements 
            // dispatch('commit_cartinfo',current_cart)
            // saving changes to the cart in order to send changes to mongodb
            commit('update_cart_change_payload', cart_change_payload)

            //commit('updatecart',current_cart)
        
        },

        //CUST SELECTION

        async cartselectionchange_cust( {commit, state, dispatch}, payload) {

            var current_cart = state.satis_cust_cart 
            var cart_change_payload = state.cart_change_payload || {}
            //console.dir(payload)

            const cart_keys = Object.keys(current_cart)
            const payloadkeys = Object.keys(payload)
                                        .map(
                                        i => 
                                        {
                                                if(cart_keys.indexOf(i) > -1)
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                }
                                                else
                                                {
                                                    current_cart[i] = payload[i]
                                                    cart_change_payload[i] = payload[i]
                                                    //console.log(i, 'is not a key of cart')
                                                }
                                        }
                                        )
            commit('update_cart_change_payload', cart_change_payload)        
        },

        //============= ADD SUB FROM CART ===============
        //
        //
        //
        //===============================================
        async addsubfromcart({commit, state, dispatch}, payload)
        {
                // REQ 
                // qty
                // nameseo
                // accountId
                // locatoin of the call to determine if add or change to 1 
                // CHECK IF ITEM IS ALREADY IN CART 

                //================ LOGIC TO SEE IF ITEM ALREADY IN CART 
                //                  - IF ITEM IN CART NEED TO HAVE DIFFERENT BEHAVIOR
                
                
                try 
                {
                const rowincart = state.account_cart.items.filter(
                    (i) => { return i.nameSEO === payload.nameseo}
                ).pop() || {} ;

                const needtoaddrow = rowincart == {};

                }  catch {
                    
                }

                //================= REQUEST PAYLOAD HERE 
                const reqsend = {
                    accountId        : state.accountinfo.id,
                    cust_name        : state.accountinfo.name,
                    cust_no          : state.accountinfo.no,
                    sales_person_code: state.accountinfo.salespersoncode,
                    cust_email       : state.accountinfo.email,
                    qty              : payload.qty,
                    item_id          : payload.item_id,
                    domain           : state.domain
                }            

                await fetch( using_urls.cartupdate, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {

                        const newcart = data.account_cart || [];
                        commit("updatecart", newcart)
                       
                        //dispatch('req_detailed_faves')
                    })
                    .then(data => {
                        dispatch('get_latest_cart_products',{})
                    })
                    .catch( error => console.error('Error:', error));

            /*
            await axios.post( using_urls.cartupdate + "/" + reqsend.nameseo, reqsend)
            .then(
                (res) => {

                    if (res.data.message === 'Sepetiniz başarıyla güncellenmiştir') {
                        const newcart = res.data.account_cart[0] || [];

                        commit("updatecart", newcart)

                        return 
                    } else {
                        console.log(" UPDATE CART  NODE WRONG MESAGE")
                    }
                    
                    // res data if 


                    // commit update acccountinfo cart

                }
            ).catch(
                (err) => {
                    console.log(err)
                }
            )
                */
        },

        //08.05.2023 - sepette isaretlemeye gore islem gelistirmesi --------------------
        async checkedrowcart({commit, state, dispatch}, payload) {

                const reqsend = {
                    accountId        : state.accountinfo.id,
                    item_id          : payload.item_id,
                    item_checked     : payload.item_checked,
                    checked_status   : payload.checked_status,
                    domain           : state.domain
                }            

                await fetch( using_urls.checkedcartupdate, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {

                        const newcart = data.account_cart || [];
                        commit("updatecart", newcart)
                       
                        //dispatch('req_detailed_faves')
                    })
                    .then(data => {
                        dispatch('get_latest_cart_products',{})
                    })
                    .catch( error => console.error('Error:', error));
        },

        async addtoreturncontrol({commit, state, dispatch}, payload) {   

                const reqsend = {
                    accountId        : state.accountinfo.id,
                    cust_name        : state.accountinfo.name,
                    cust_no          : state.accountinfo.no,
                    sales_person_code: state.accountinfo.salespersoncode,
                    cust_email       : state.accountinfo.email,
                    qty              : payload.qty,
                    item_id          : payload.item_id,
                    domain           : state.domain
                }

                var account_data = {};

                // console.log("reqsend:" + JSON.stringify(reqsend))

                await fetch( using_urls.returncartinfo, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {

                        account_data = data;

                        // console.log("account_data:" + JSON.stringify(account_data))
                    })
                    .catch( error => console.error('Return Cart Control Error:', error));

            return account_data

        },

        async addtoreturncart({commit, state, dispatch}, payload) {   

            const reqsend = {
                accountId        : state.accountinfo.id,
                cust_name        : state.accountinfo.name,
                cust_no          : state.accountinfo.no,
                sales_person_code: state.accountinfo.salespersoncode,
                cust_email       : state.accountinfo.email,
                return_info      : payload.return_info,
                domain           : state.domain
            }

            // console.log("reqsend:" + JSON.stringify(reqsend))

            var invoice_info = {} 

            await fetch( using_urls.returncartupdate, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    invoice_info = data

                    // console.log("Son Dönüş:" + JSON.stringify(data))
                    const newreturncart = data.account_returncart || [];
                    commit("updatereturncart", newreturncart)
                   
                })
                .catch( error => console.error('Return Cart Error:', error));

                return invoice_info
        },

        async deletefromreturncart({commit, state}, payload){

            const reqsend = {
                accountId        : state.accountinfo.id,
                item_id          : payload.item_id,
                entrykey         : payload.entrykey,
                type             : payload.type,
                domain           : state.domain
            }

            var invoice_info = {} 

            await fetch( using_urls.returncartdelete, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    invoice_info = data

                    // console.log("Silme Dönüşü:" + JSON.stringify(data))
                    const newreturncart = data.account_returncart || [];
                    commit("updatereturncart", newreturncart)
                   
                })
                .catch( error => console.error('Return Cart Error:', error));

                return invoice_info
        },

        // ! SATIS ADD SUB FROM CART
        async satis_addsubfromcart({commit, state, dispatch}, payload)
        {   
            const reqsend = {
                user                : state.satis_account.user,
                domain              : state.satis_account.domain,
                name                : state.satis_account.name, 

                user_id             : state.satis_account._id,

                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,
                selected_company_name : state.selected_company.name,
                selected_company_email : state.selected_company.email,


                qty                 : payload.qty,
                item_id             : payload.item_id,
            }

            // console.dir(state.satis_account)
        

            await fetch( using_urls.satis_add_to_cart, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    const newcart = data.account_cart || [];
                    dispatch('satis_get_latest_cart_details',newcart)
                    commit("save_satis_cart", newcart)
                   
                    //dispatch('req_detailed_faves')
                })
                .catch( error => console.error('Error:', error));
        },
        // ! SATIS ADD SUB FROM CUST CART 
        async satis_addsubfromcustcart( {commit, state, dispatch}, payload)
        {

            const reqsend = {
                user                : state.satis_account.user,
                domain              : state.satis_account.domain,
                name                : state.satis_account.name, 

                user_id             : state.satis_account._id,

                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,
                selected_company_name : state.selected_company.name,
                selected_company_email : state.selected_company.email,

                qty                 : payload.qty,
                item_id             : payload.item_id,
            }        
            console.log("Fetch !!!!")
            await fetch( using_urls.satis_add_to_cust_cart, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    console.log("AC is " , data.account_cart )
                    const newcart = data.account_cart || [];
                    dispatch('satis_get_latest_cart_details',newcart)
                    commit("save_satis_cust_cart", newcart)
                   
                    //dispatch('req_detailed_faves')
                })
                .catch( error => console.error('Error:', error));

        },

        async transfer_satis_cust_cart( {commit, state, dispatch}, payload)
        {

            const reqsend = {
                user                : state.satis_account.user,
                domain              : state.satis_account.domain,
                name                : state.satis_account.name, 

                user_id             : state.satis_account._id,

                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,

                qty                 : payload.qty,
                item_id             : payload.item_id,
            }


            dispatch('satis_addsubfromcustcart', reqsend )
            reqsend['qty'] = 0
            dispatch('satis_addsubfromcart', reqsend)
        },

        //================== GET MORE RESULTS PAGE ===================
        //
        //                              payload = {
        //                                         page:
        //                                         sort:
        //                                         filtre:
        //                                         requestred:
        //                                         isonlyload:
        //                                     }       
        //=============================================================
        async loadmoreresults ({commit, state}, payload) {
            
            
            // CHECK PAYLOAD PAGE TO SEE AMOUNT OF RESULTS LOADED and TOTAL AMOUNT OF POSSIBLE RESULTS
            // IF NOT ALL RESULTES ARE LOADED LOAD CHUCK OF NEW DATA AND PUSH INTO STATE VAR USED 
            // INCREMENT THE AMOUNT OF LOADED RESUKLTS
            // 

                        // req = {                              res = {
                        //     searchword:                          message:
                        //     filtre:                              totaldocnumber:
                        //     sort:
                        //     numofreturn:
                        //     startofresultreturn:
                        // }    }

            const isonlyload            = payload.isonlyload
            const page                  = payload.page
            const numofreturn           = 25


            const doesloaded_pages     = 'loadedpages' in state
            var  doesloadedexist       =  false
            if(doesloaded_pages) doesloadedexist = page in state.loadedpages;

            var search_filter         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_filter  :  payload.search_filter || ' ';
            var search_string         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_string  :  payload.search_string || ' ';
            var filtre                = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.filtre         :  payload.filtre        || '';
            var sort                  = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.sort           :  payload.sort          ;
            var startofresultreturn   = (doesloadedexist && isonlyload) ? state.loadedpages[page].startofresultreturn    : 0;
            var ids_seen              = (doesloadedexist && isonlyload) ? state.loadedpages[page]['data'].map( i=> i._id)    : [];
            var isopen                = (doesloadedexist && isonlyload) ? state.loadedpages[page]['isopen']    : payload.isopen || 0;
            
            const reqsend = { 
                accountid           : state.accountinfo.id,
                account_name        : state.accountinfo.name,
                search_string       : search_string,
                filtre              : filtre,
                sort                : sort,
                startofresultreturn : startofresultreturn,
                ids_seen            : ids_seen,
                domain              : state.domain,
                search_filter       : search_filter,
                account_number      : state.accountinfo.no,
                isopen              : isopen,
                account_rules       : state.accountinfo.rules
            }

            //console.dir(reqsend)

            await fetch( using_urls[page], {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {   
                    

                    //console.dir(data.data)
                    
                const commitreq = {
                    reqsent             : reqsend,
                    data                : data.data,
                    isonlyload          : isonlyload,
                    page                : page,
                    startofresultreturn : startofresultreturn + numofreturn,
                    isopen              : isopen
                }


                commit("loadmorepagesmut", commitreq) 
                //dispatch('req_detailed_faves')
                })
                .catch((error) => {
                console.error('Error:', error);
                });
            return false

            /*
            await axios.post ( 
                using_urls[page] , reqsend
            )
            .then(
                (res) => {
                    const commitreq = {
                        reqsent             : reqsend,
                        data                : res.data.data,
                        isonlyload          : isonlyload,
                        page                : page,
                        startofresultreturn : startofresultreturn + numofreturn
                    }
                    commit("loadmorepagesmut", commitreq)             
                }
            )
            .catch(
                (err) => {
                    console.log(err)
                }
            )
            */
        },
        //============  ADD SUB FROM TO FAVORITES  ==============
        //
        //
        //  
        //        payload = { nameSEO: addsub: accountid: }
        //=======================================================
            // req = {
            //     nameseo:
            //     accountid:
        async addtofavorites({dispatch, commit, state}, payload) {

             const checkifinfavorite = state.accountinfo.favorite_products.filter(
             (i) => {
                 return i.productid == payload._id
             })
             .length == 1

            //console.dir(payload)



            // favoriler_add              :   '/favorilere-ekle',
            // favorilar_sub              :   '/favorilere-cikar',


            const add_or_sub_urls = using_urls[payload.action_type]

            const reqsend ={
                item_id  : payload._id,
                accountid: state.accountinfo.id,
                domain   : state.domain
            }
            
            printdir('addtofaves', 'reqsend ', reqsend)
            await fetch( add_or_sub_urls, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    //console.dir(data)
                    if(data.save_favorites){

                        commit('save_favorites',data.save_favorites);
                        dispatch('req_detailed_faves')
                    
                    }

                })
                .catch( error => console.error('Error:', error));


            /*
            await axios.post(
                using_urls.favoriler,
                reqsend
            )
            .then(
                (res) => {
                    console.dir(res)
                }
            )
            .catch(
                err => console.dir(err)
            )
            */
        },
        //=================== GET DTAILED FAVORITES =============
        //
        //
        //
        //
        //
        //=======================================================


            // req = {
            //     accountid:
            //     itemsids: [ '_id']
            // }
        async req_detailed_faves( {commit, state, dispatch}) {
            
            const items_ids = state.accountinfo.favorite_products.map(
                i => i.itemID
            )

            // console.log("items_ids:" + items_ids)


            //console.log(state.accountinfo)
            const reqsend = {
                accountid: state.accountinfo.id,
                items_ids : items_ids,
                domain   : state.domain,
                account_rules: state.accountinfo.rules
            }

            await fetch( using_urls.getitemsbyid, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    // B 22.03.2023 - pasife alinmis urunlerin favorilerden cikarilmasi icin gelistirme eklendi
                    const fav_control = (data.itemsdetails.length > 0) ? data.itemsdetails.filter((i) => (i.b2b == 1)) : [];

                    if (data.itemsdetails.length > 0){
                        for (let i = 0; i < data.itemsdetails.length ; i++){
                            if (data.itemsdetails[i].b2b == 0){
                                data.itemsdetails[i]['action_type'] = 'favorilar_sub'
                                // console.log('addtofavorites : ' + JSON.stringify(data.itemsdetails[i]))
                                dispatch('addtofavorites', data.itemsdetails[i] )
                            }
                        }
                    }
                    // E 22.03.2023 - pasife alinmis urunlerin favorilerden cikarilmasi icin gelistirme eklendi

                    commit('save_detailed_favorites',fav_control) // 22.03.2023 - yapilan gelistirmeye gore "data.itemsdetails" degerinden sadece b2b == 1 olanlar "fav_control" olarak degistirildi
                })
                .catch( error => console.error('Error:', error));
            
        },
        
        // * Sepetimin save_past_orders kullanarak doldurmak
        async req_past_orders({commit, state}) {
            
            const reqsend = {
                accountid : state.accountinfo.id,
                domain    : state.domain
            }

            await fetch( using_urls.get_past_orders, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    commit('save_past_orders',data.data)
                })
                .catch( error => console.error('Error:', error));
            
            // await axios.post(
            //     using_urls.get_past_orders,
            //     reqsend
            // )
            // .then(
            //     (res) => {
            //         commit('save_past_orders', res.data)
            //     }
            // )
            // .catch(
            //     err => console.dir(err)
            // )

        },

        async act_erase_bank_pos_list_from_settings({commit})
        {
            commit('erase_bank_pos_list_from_settings')
        },
        async  getNewProducts({commit, state}, payload){
            const reqsend = {
                accountid : state.accountinfo.id,
                domain    : state.domain,
                account_rules: state.accountinfo.rules
            }
            await fetch('/newProducts',
            {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then( res => res.json())
            .then( data => {
                const commpayload = {
                        data: data.data,
                        slider: 'new'
                    }
                commit('save_sliders_data', commpayload)
            })
            .catch( err => console.error('ERROR: ', err));
        },
        async  getPopProducts({commit, state}, payload){
            const reqsend = {
                accountid : state.accountinfo.id,
                domain    : state.domain,
                account_rules: state.accountinfo.rules
            }
            

            await fetch('/populerProducts',
            {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then( res => res.json())
            .then( data => {
                const commpayload = {
                        data: data.data,
                        slider: 'pop'
                    }

                //console.dir(commpayload)
                commit('save_sliders_data', commpayload)
            })
            .catch( err => console.error('ERROR: ', err));
        },
        async save_aydin_settings( { commit, state}, payload)
        {
            const reqsend = {
                accountid        : state.accountinfo.id,
                domain           : state.domain,
                aydin_conf       : payload.conf,
                aydin_email_conf : payload.email_conf,
                aydin_sms_conf   : payload.sms_conf
            }

            await fetch('/update_aydin',
            {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then( res => res.json())
            .then( data => {
                commit('login_initialization'  , data.account_info)
                
                //commit('save_sliders_data', commpayload)
            })
            .catch( err => console.error('ERROR: ', err));

        },

        //? =============== DIRECT CREDIT CARD PAYMENT  ==========================================================0

        async get_account_info({ commit }, payload){ //kolay odeme ile gelen musteri bilgileri cekiliyor ----------------------------

            const vendorId = payload.vendorId
            var account_data = {};

            const reqsend = {
                vendorId   : vendorId,
            }

            await fetch( using_urls.easy_payment_user_info, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                account_data = data
                if (data.domain_info != null){ //kullanici bulundu ise
                    commit('commit_domain_info'  , data.domain)
                    commit('login_initialization'  , data.account_info)
                }
            })
            .catch( error => console.error('Error:', error));

            return account_data
        },
        async transaction_payment_info({state, commit, dispatch }, payload){ //kolay odeme ile gelen odeme hareketi bilgileri cekiliyor -------------------------------------

            const order_no  = payload.order_no 
            const domain    = payload.domain

            const reqsend = {
                order_no  : order_no,
                domain    : domain,
            }

            var data_info = null;

            await fetch( using_urls.easy_payment_pay_info, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        // console.log("data: " + JSON.stringify(data))
                        data_info = data;
                        return data_info
                    })
                    .catch( error => console.error('Error:', error));

            // console.log("data_info: " + JSON.stringify(data_info))
    
            return data_info
        },

        async payment_get_bank_info({ dispatch }, payload){ //credit card no onkeypress ----------------------------------------------

            const user_bank_bin   = payload.user_bank_bin
            const user_total_cari = payload.user_total_cari
            const user_cari_vade  = payload.user_cari_vade
            const account_no      = payload.account_no

            // console.log(user_bank_bin)
            // return

            const reqsend = {
                user_bank_bin   : user_bank_bin,
                user_total_cari : user_total_cari,
                user_cari_vade  : user_cari_vade,
                account_no      : account_no,
            }

            var payment_data = {}

            await fetch( using_urls.user_make_payment, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    payment_data = data;

                    //console.log(`Data:${JSON.stringify(payment_data)}`)

                    // data.mainpos_code
                    // data.mainpos_installments
                    // data.mainpos_card_name
                    // data.status3Dopt
                    
                })
                .catch( error => console.error('Error:', error));

                dispatch('commit_payment_installment', payment_data)

                return
        },
        async cash_make_payment({state, commit, dispatch }, payload){

            const accountId         = state.accountinfo.id
            const cust_no           = state.accountinfo.no
            const cust_name         = state.accountinfo.name
            const domain            = state.domain
            const card_number       = payload.card_number 
            const card_date_month   = payload.card_date_month
            const card_date_year    = payload.card_date_year
            const card_cvv          = payload.card_cvv
            const card_ownername    = payload.card_ownername
            const card_gsm          = payload.card_gsm
            const installment       = payload.installment
            const mainpos_code      = payload.mainpos_code
            const payment_method    = payload.payment_method
            const payment_amount    = payload.payment_amount
            const payment3D         = payload.payment3D
            const payment_page      = payload.payment_page || "SystemPay" // 21.11.2022 - Kolay-ode 3D gelistirmesi

            console.log('cash_make_payment domain',domain)


            const reqsend = {
                accountId         : accountId,
                cust_no           : cust_no,
                cust_name         : cust_name,
                domain            : domain,
                card_number       : card_number,
                card_date_month   : card_date_month,
                card_date_year    : card_date_year,
                card_cvv          : card_cvv,
                card_ownername    : card_ownername,
                card_gsm          : card_gsm,
                installment       : installment,
                mainpos_code      : mainpos_code,
                payment_method    : payment_method,
                payment_amount    : payment_amount,
                payment3D         : payment3D,
                payment_page      : payment_page, // 21.11.2022 - Kolay-ode 3D gelistirmesi
            }

            var payStatus = {};
            

            if ((payment3D == true) || (payment3D == "true")){


                await fetch( using_urls.cash_make_payment3D, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                        payStatus = data
                        
                    })
                    .catch( error => console.error('Error:', error));

                    return payStatus

            } else {

                await fetch( using_urls.cash_make_payment, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                        // console.log("data: " + JSON.stringify(data))
    
                        if ((payment_page == "CartPay") && (data.payStatus == true)){ //odeme basarili ise isleme devam edecek
                            dispatch('complete_order', data)
                        }

                        payStatus = data;
                        
                    })
                    .catch( error => console.error('Error:', error));

                    //console.log(payStatus)
    
                    return payStatus
            }
        },
        async cash_make_paymenttest({state, commit, dispatch }, payload){

            const accountId         = state.accountinfo.id
            const cust_no           = state.accountinfo.no
            const cust_name         = state.accountinfo.name
            const domain            = state.domain
            const card_number       = payload.card_number 
            const card_date_month   = payload.card_date_month
            const card_date_year    = payload.card_date_year
            const card_cvv          = payload.card_cvv
            const card_ownername    = payload.card_ownername
            const card_gsm          = payload.card_gsm
            const installment       = payload.installment
            const mainpos_code      = payload.mainpos_code
            const payment_method    = payload.payment_method
            const payment_amount    = payload.payment_amount
            const payment3D         = payload.payment3D
            const payment_page      = payload.payment_page || "SystemPay" // 21.11.2022 - Kolay-ode 3D gelistirmesi

            console.log('cash_make_paymenttest domain',domain)


            const reqsend = {
                accountId         : accountId,
                cust_no           : cust_no,
                cust_name         : cust_name,
                domain            : domain,
                card_number       : card_number,
                card_date_month   : card_date_month,
                card_date_year    : card_date_year,
                card_cvv          : card_cvv,
                card_ownername    : card_ownername,
                card_gsm          : card_gsm,
                installment       : installment,
                mainpos_code      : mainpos_code,
                payment_method    : payment_method,
                payment_amount    : payment_amount,
                payment3D         : payment3D,
                payment_page      : payment_page, // 21.11.2022 - Kolay-ode 3D gelistirmesi
            }

            var payStatus = {};
            

            if ((payment3D == true) || (payment3D == "true")){


                await fetch( using_urls.cash_make_paymenttest3D, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                        payStatus = data
                        
                    })
                    .catch( error => console.error('Error:', error));

                    return payStatus

            } else {

                await fetch( using_urls.cash_make_paymenttest, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                        // console.log("data: " + JSON.stringify(data))
    
                        if ((payment_page == "CartPay") && (data.payStatus == true)){ //odeme basarili ise isleme devam edecek
                            dispatch('complete_order', data)
                        }

                        payStatus = data;
                        
                    })
                    .catch( error => console.error('Error:', error));

                    //console.log(payStatus)
    
                    return payStatus
            }
        },
        // ? =============== DIRECT CREDIT CARD PAYMENT  ==============================
        // ? ================ PAYMENT  ================
        //
        //
        //
        // * ==========================================
        async make_payment({state, commit, dispatch }, payload){

            
            const accountId         = state.accountinfo.id
            const cust_no           = state.accountinfo.no
            const cust_name         = state.accountinfo.name
            const domain            = state.domain
            const card_number       = payload.card_number 
            const card_date_month   = payload.card_date_month
            const card_date_year    = payload.card_date_year
            const card_cvv          = payload.card_cvv
            const card_ownername    = payload.card_ownername
            const card_gsm          = payload.card_gsm
            const installment       = payload.installment
            const mainpos_code      = payload.mainpos_code
            const payment_method    = payload.payment_method

            console.log('make_payment domain', domain)

            //sepet toplami bulunuyor ------------------------------------------------------------------------------------
            const acc_cart     = state   ['account_cart']
            const settings     = state   ['settings']
            const cart_items   = acc_cart['items']
            const item_details = state['account_item_details']            
            const discount_5_  = await dispatch('get_discount5')
            var order_amount   = 0

            for(let i = 0; i <cart_items.length; i++ )
            {   
                if(!cart_items[i].checked)
                {
                    continue
                }
                var item_for_list = {}
                var detail_item   = {}
                for(let j=0; j<item_details.length; j++)
                {
                    if( cart_items[i].itemID == item_details[j]._id )
                    {
                        detail_item = item_details[j]
                        break
                    }

                }
                const rules        = (detail_item != {}) ? detail_item['rules'] : {}

                if(rules == {})
                {continue}

                item_for_list['discount_1']     = 0 // ? müsteri iskontosu
                item_for_list['discount_2']     = 0 // ? müsteri iskontosu
                item_for_list['discount_3']     = 0 // ? müsteri / kampanya iskontosu
                item_for_list['discount_4']     = 0 // ? dbs iskontosu
                item_for_list['discount_5']     = discount_5_ // ? k.karti/havale iskontosu

                item_for_list['listprice_lcy']  = detail_item.listprices || 0

                item_for_list['curr']           = detail_item.listprices_curr || " "
                item_for_list['curr_rate']      = await dispatch('get_kurr', item_for_list['curr'])
                
                item_for_list['qty']            = cart_items[i].qty
                item_for_list['itemID']         = cart_items[i].itemID
                item_for_list['name']           = cart_items[i].name
                item_for_list['itemno']         = cart_items[i].itemno
                item_for_list['olditemno']      = detail_item.olditemno
                item_for_list['nameSEO']        = cart_items[i].nameSEO

                item_for_list['desi']           = cart_items[i].desi
                item_for_list['imageseo']       = detail_item.imageseo
                item_for_list['image']          = (detail_item.images == undefined) ? null : detail_item.images[0]
                item_for_list['return_qty']     = cart_items[i].return_qty || 0

                item_for_list['tax_rate']       = Number(detail_item.taxrate)

                item_for_list['price']          = cart_items[i].price


                // Fiyatlandirma yapiliyor ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                var retthis           = 0
                var net_price_camp    = 0
                var net_price_private = 0

                var listprices        = detail_item.listprices || 0
                var listprices_curr   = detail_item.listprices_curr || " "

                var calcthis          = 0
                var calcthis_private  = 0
                var calcthis1         = 0
                var calcthis2         = 0
                var price_curr        = " "
                var price_curr1       = " "
                var price_curr2       = " "
                var curr_rate         = 0
                var curr_rate1        = 0
                var curr_rate2        = 0
                var return_rules       = " "
                var return_rules1      = " "
                var return_rules2      = " "
                var discount_1        = 0
                var discount_2        = 0
                var discount_3        = 0
                var discount_4        = 0
                var discount_1_1      = 0
                var discount_2_1      = 0
                var discount_3_1      = 0
                var discount_4_1      = 0
                var discount_1_2      = 0
                var discount_2_2      = 0
                var discount_3_2      = 0
                var discount_4_2      = 0

                var vendorprices      = detail_item.prices || {}

                if ((settings.poolprice_view == true) && (vendorprices != null)){
                  listprices = await dispatch('get_poolprice', vendorprices)
                  listprices_curr = " "
                }

                var CampDisc  = rules.filter((i) => { return i.type == 'iKM'}) // ? Kampanya iskontosu (1)
                var CusDisc   = rules.filter((i) => { return i.type == 'iMS'}) // ? Müşteri iskontosu (2)
                var CusNet    = rules.filter((i) => { return i.type == 'NTF'}) // ? İskontolar Hariç Tutulan Müşteri Fiyatı (3.a)
                var CusPrice  = rules.filter((i) => { return i.type == 'iDF'}) // ? İskontolar Dahil Edilen Müşteri Fiyatı (3.b)
                var CampNet   = rules.filter((i) => { return i.type == 'NTK'}) // ? İskontolar Hariç Tutulan Kampanyalı Fiyat (4.a)
                var CampPrice = rules.filter((i) => { return i.type == 'iDK'}) // ? İskontolar Dahil Edilen Kampanyalı Fiyat (4.b)
                var CustProfit= rules.filter((i) => { return i.type == 'MKM'}) // ? Müşteri Kar Marjı (5)


                if (CusNet[0] != undefined){ // Net fiyat varsa --------------------------------------------------------------------------------------------------
                    calcthis_private           = Number(CusNet[0].value[0]) || 0
                    price_curr                 = CusNet[0].curr  || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])
                    
                    if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                        if (payment_method != undefined) {
                            if (payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private

                } else if (CusPrice[0] != undefined){ // Musteri net fiyat yoksa iskontolar dahil musteri fiyatina bakilacak ----------------------------------------
                    calcthis_private = Number(CusPrice[0].value[0]) || 0
                    price_curr       = CusPrice[0].curr  || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu                
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (payment_method != undefined) {
                            if (payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                    
                } else { // Net ve iskontolar dahil musteri fiyatı yoksa liste fiyati uzerinden musteri iskontolari dusulmus fiyatina bakilacak -----------------------------------
                    calcthis_private = listprices      || 0
                    price_curr       = listprices_curr || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (payment_method != undefined) {
                            if (payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                }

                net_price_private = calcthis_private * await dispatch('get_kurr', price_curr); // tl tutara cevriliyor

                retthis = net_price_private // Pesin ve taksitli fiyat hesaplamalari icin once en dusuk fiyat aliniyor

                // B Stok varsa kampanyali fiyatlar hesaplaniyor
                if (detail_item.inventory > 0) {
                    // B Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor
                    if (CampNet[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampNet[0].value[0]) || 0;
                        price_curr1 = CampNet[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        discount_1_1 = 0
                        discount_2_1 = 0
                        discount_3_1 = 0
                        discount_4_1 = 0
                        if (CampNet[0].tag.indexOf('€') > 0){
                            var ret_rules = CampNet[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                            if (payment_method != undefined) {
                                if (payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                        }
                    } else if (CampPrice[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampPrice[0].value[0]) || 0;
                        price_curr1 = CampPrice[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        if (CampPrice[0].tag.indexOf('€') > 0){
                            var ret_rules = CampPrice[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[0]; discount_1_1 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[1]; discount_2_1 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[2]; discount_3_1 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (payment_method != undefined) {
                                if (payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                            // if (CusDisc[0].value[4] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);  }
                        }
                    }
                    // E Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor

                    // B Kampanya iskontosu hesaplaniyor
                    if ((CampDisc[0] != undefined) && (CusNet[0] == undefined)) { // Kampanya iskontoları dahil fiyatlar icin
                        calcthis2   = (CusPrice[0] != undefined) ? (Number(CusPrice[0].value[0]) || 0)   : (listprices     || 0); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        price_curr2 = (CusPrice[0] != undefined) ? (CusPrice[0].curr  || " ") : (listprices_curr || " "); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        curr_rate2  = await dispatch('get_kurr', price_curr2)
                        if (CampDisc[0].tag.indexOf('€') > 0){
                            var ret_rules = CampDisc[0].tag.split('€');
                            return_rules2 = ret_rules[1];
                        }

                        if (CampDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CampDisc[0].value[2]; discount_3_2 = 100 - Number(CampDisc[0].value[2]);  } // ? kampanya iskontosu
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[0]; discount_1_2 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[1]; discount_2_2 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[2]; discount_3_2 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (payment_method != undefined) {
                                if (payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                            }
                            // if (CusDisc[0].value[4] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);  }
                            // if (CusDisc[0].value[4] != 100){
                            //     calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);
                            // } else { // müsteri iskontosunu ezmemek icin kampanya iskontosu 5.iskontoya aliniyor
                            //     if (CusDisc[0].value[2] == 100){
                            //         item_for_list['discount_5'] = 100 - Number(CampDisc[0].value[2])
                            //     }
                            // }
                        }
                    }
                    // E Kampanya iskontosu hesaplaniyor

                    // B Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    if ((calcthis1 != 0) && (calcthis1 < calcthis2)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis2 != 0) && (calcthis2 < calcthis1)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    } else if ((calcthis1 != 0) && (calcthis2 == 0)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis1 == 0) && (calcthis2 != 0)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    }
                    // E Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    
                    net_price_camp = calcthis * await dispatch('get_kurr', price_curr); // İskontolu fiyat tl tutara cevriliyor

                    if ((calcthis1 != 0) || (calcthis2 != 0)){
                        if ((net_price_camp < net_price_private) || (net_price_private == 0)){ //20.12.2022 - degisiklik yapildi
                            retthis = net_price_camp
                            // item_for_list['campain_code'] = return_tags
                            item_for_list['price']      = calcthis
                            item_for_list['curr']       = price_curr
                            item_for_list['curr_rate']  = await dispatch('get_kurr', item_for_list['curr'])
                            item_for_list['discount_1'] = discount_1
                            item_for_list['discount_2'] = discount_2
                            item_for_list['discount_3'] = discount_3
                            item_for_list['discount_4'] = discount_4
                        } else {
                            return_rules = " "
                        }
                    } else {
                        return_rules = " "
                    }
                }

                item_for_list['campain_code'] = return_rules

                if (payment_method != undefined) { //Eger K.Karti veya Havale/EFT secildi ise
                    if ((payment_method == "1")||(payment_method == "2")) {
                        item_for_list['price'] = item_for_list['price'] * 0.01 * ((100 - item_for_list['discount_5']));
                    }
                }

                var b2b_match_service_status = false;
                if (state['arsal_vendor_info'].b2b_match_service_status[domain] != undefined){
                  if (state['arsal_vendor_info'].b2b_match_service_status[domain].status != undefined){
                    b2b_match_service_status = state['arsal_vendor_info'].b2b_match_service_status[domain].status;
                  }
                }
        
                if ((CustProfit[0] != undefined) && ((b2b_match_service_status == false) || ((b2b_match_service_status == true) && (detail_item.productmatch == true)))){
                    if (Number(CustProfit[0].value[0]) != 100){  item_for_list['price'] = item_for_list['price'] * 0.01 * Number(CustProfit[0].value[0]); } // ? Müşteri Kar Marjı (5)
                }

                item_for_list['price_lcy']      = item_for_list['price'] * item_for_list['curr_rate']
                item_for_list['lineamount_lcy'] = item_for_list['qty'] * item_for_list['price_lcy'] 
                item_for_list['linetax_lcy']    = item_for_list['lineamount_lcy'] * (item_for_list['tax_rate'] / 100)
                item_for_list['linetotal_lcy']  = item_for_list['lineamount_lcy'] + item_for_list['linetax_lcy']
                order_amount                    = order_amount + item_for_list['linetotal_lcy']

            }
            //sepet toplami bulunuyor ------------------------------------------------------------------------------------

            const reqsend = {
                accountId         : accountId,
                cust_no           : cust_no,
                cust_name         : cust_name,
                domain            : domain,
                card_number       : card_number,
                card_date_month   : card_date_month,
                card_date_year    : card_date_year,
                card_cvv          : card_cvv,
                card_ownername    : card_ownername,
                card_gsm          : card_gsm,
                installment       : installment,
                mainpos_code      : mainpos_code,
                payment_method    : payment_method,
                order_amount      : order_amount.toFixed(2),
            }

            var payStatus = false;
            
            //console.log(JSON.stringify(reqsend))

            await fetch( using_urls.make_payment, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    if (data.payStatus == true){ //odeme basarili ise isleme devam edecek
                        dispatch('complete_order', data)
                        payStatus = true;
                    }
                    
                })
                .catch( error => console.error('Error:', error));

                //console.log("make_payment-payStatus:" + payStatus)

                return payStatus

        },
        
        async harekeleri_al({state, commit }, payload)
        {
            const reqsend = {
                account_number        : state.accountinfo.no,
                domain                : state.domain,
                ids_seen              : [],
            }

            await fetch(using_urls['cari_hareket'],
            {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then( res => res.json())
            .then( data => {

                //console.log('cari_harekeler')
                //console.dir(data)
                commit('save_cari_entry',data.data)
                //commit('save_sliders_data', commpayload)
            })
            .catch( err => console.error('ERROR: ', err));


        },

        // B 24.11.2022  -   Cari hareketlerin sifreli hale getirilmesi
        async new_pass_document_list({state, commit}, payload){

            const reqsend = {
                new_pass    : payload.new_pass,
                account_no  : state.accountinfo.no,
                domain      : state.domain,
            }

            await fetch( using_urls.document_addnewpass, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    // console.log("data.new_pass:" + JSON.stringify(data.new_pass))
                    commit('save_document_new_pass', data.new_pass)
                })
                .catch( error => console.error('Error:', error));

        },
        // E 24.11.2022  -   Cari hareketlerin sifreli hale getirilmesi

        async satis_user_new_pass_save({state, commit}, payload){

            const reqsend = {
                user_new_pass   : payload.user_new_pass,
                cust_no         : payload.selected_cust_no,
                domain          : state.domain,
            }

            await fetch( using_urls.satis_user_new_pass, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    console.log("data.user_new_pass:" + JSON.stringify(data.new_pass))
                    //commit('save_document_new_pass', data.new_pass)
                })
                .catch( error => console.error('Error:', error));
        },

        async satis_cust_new_pass_save({state, commit}, payload){

            const reqsend = {
                cust_new_pass   : payload.cust_new_pass,
                cust_no         : payload.selected_cust_no,
                pass_control    : payload.cust_pass_control,
                domain          : state.domain,
            }

            await fetch( using_urls.satis_cust_new_pass_control, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    // console.log("data.cust_new_pass:" + JSON.stringify(data.new_pass))
                    //commit('save_document_new_pass', data.new_pass)
                })
                .catch( error => console.error('Error:', error));
        },

        async satis_cust_privinsta_save({state, commit}, payload){

            const reqsend = {
                action_type               : payload.action_type,
                selected_cust_no          : payload.selected_cust_no,
                installment_mainpos_code  : payload.installment_mainpos_code,
                installment_count         : payload.installment_count,
                installment_cartdiscount  : payload.installment_cartdiscount,
                installment_status        : payload.installment_status,
                domain                    : state.domain,
            }

            // console.log("reqsend:" + JSON.stringify(reqsend))

            var return_data = ""

            await fetch(using_urls.satis_cust_privinsta, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // commit('save_account_cart_item_detail', data.itemsdetails)

                return_data = data

            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_cust_collection_save({state, commit}, payload){
            
            const reqsend = (payload.action_type == "add") ? {
                    domain              : state.domain,
                    action_type         : payload.action_type,
                    collection_date     : payload.collection_date,
                    cash_amount         : payload.cash_amount,
                    cash_amount_curr    : payload.cash_amount_curr,
                    collection_note     : payload.collection_note,
                    payment_method      : payload.payment_method,
                    collection_type     : payload.collection_type,

                    cust_id             : payload.cust_id,
                    cust_no             : payload.cust_no,
                    cust_name           : payload.cust_name,

                    sales_person_id     : state.satis_account._id,
                    sales_person_code   : state.satis_account.user,
                    sales_person_name   : state.satis_account.name, 
                } : {
                    domain              : state.domain,
                    action_type         : payload.action_type,
                    collection_id       : payload.collection
                }

            console.log("collection-reqsend:" + JSON.stringify(reqsend))

            var return_data = ""

            await fetch(using_urls.satis_cust_collection_save, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // commit('save_account_cart_item_detail', data.itemsdetails)

                return_data = data

            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_user_actions({state, commit}, payload){

            const reqsend = {
                user_firstname    : payload.user_firstname,
                user_surname      : payload.user_surname,
                user_email        : payload.user_email,
                user_phone        : payload.user_phone,
                user_oldusername  : payload.user_oldusername,
                user_username     : payload.user_username,
                user_pass         : payload.user_pass,
                user_type         : payload.user_type,
                user_permissions  : payload.user_permissions,
                user_status       : payload.user_status,
                user_platform_b2b : payload.platform_b2b,
                user_platform_b2c : payload.platform_b2c,
                user_data         : payload.user_data,
                action_type       : payload.action_type,
                domain            : state.domain,
            }

            // console.log("reqsend:" + JSON.stringify(reqsend))

            var return_data = ""

            await fetch(using_urls.satis_user_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // commit('save_account_cart_item_detail', data.itemsdetails)

                return_data = data

            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_product_actions({state, commit}, payload){

            const reqsend = {
                product_id              : payload.product_id,
                product_code            : payload.product_code,
                product_oldcode         : payload.product_oldcode,
                product_olditemno       : payload.product_olditemno,
                product_name            : payload.product_name,
                product_listprices      : payload.product_listprices,
                product_listprices_curr : payload.product_listprices_curr,
                product_oem             : payload.product_oem,
                product_brand           : payload.product_brand,
                product_unitofmeasure   : payload.product_unitofmeasure,
                product_discount_group  : payload.product_discount_group,
                product_moq             : payload.product_moq,
                product_taxrate         : payload.product_taxrate,
                product_inventory       : payload.product_inventory,
                product_incoming_stock  : payload.product_incoming_stock,
                product_status          : payload.product_status,
                product_b2b             : payload.product_b2b,
                product_b2c             : payload.product_b2c,
                product_new             : payload.product_new,
                new_discount_group_code : payload.new_discount_group_code,
                new_discount_group_name : payload.new_discount_group_name,
                product_rules           : payload.product_rules,
                upload_data             : payload.upload_data,
                action_type             : payload.action_type,
                domain                  : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_product_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                commit('save_settings_discount_group', data)
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_product_info({state}, payload){

            const reqsend = {
                product_code    : payload.product_code,
                domain          : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_product_info, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data.item_info
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_company_actions({state}, payload){

            const reqsend = {
                company_id              : payload.company_id,
                company_code            : payload.company_code,
                company_oldcode         : payload.company_oldcode,
                company_contact         : payload.company_contact,
                company_name            : payload.company_name,
                company_addres          : payload.company_addres,
                company_city            : payload.company_city,
                company_county          : payload.company_county,
                company_phone           : payload.company_phone,
                company_faxno           : payload.company_faxno,
                company_email           : payload.company_email,
                company_taxareacode     : payload.company_taxareacode,
                company_taxno           : payload.company_taxno,
                company_salespersoncode : payload.company_salespersoncode,
                company_username        : payload.company_username,
                company_pass            : payload.company_pass,
                company_list_pass       : payload.company_list_pass,
                company_status          : payload.company_status,
                action_type             : payload.action_type,
                domain                  : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_company_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_company_info({state}, payload){

            const reqsend = {
                company_code    : payload.company_code,
                domain          : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_company_info, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data.company_info[0]
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_settings_actions({state, commit}, payload){

            const reqsend = {
                exchange_select : payload.exchange_select,
                domain          : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_setting_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                commit('save_settings_exchange', data)
                // return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async slide_actions({commit}, payload){

            commit('save_settings_sliders', payload)

        },

        async side_slide_actions({commit}, payload){

            commit('save_settings_side_sliders', payload)

        },

        async satis_settings_columns_actions({state, commit}, payload){

            const reqsend = {
                newColumnsSort : payload.newColumnsSort,
                page           : payload.page,
                domain         : state.domain,
            }

            await fetch(using_urls.satis_settings_columns, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {

            })
            .catch( error => console.error('Error:', error));

            console.log("reqsend:" + JSON.stringify(reqsend))
            commit('save_settings_columns', reqsend)
        },

        async get_latest_cart_products({state,commit}, payload)
        {

            const items_ids = (state.account_cart.items == undefined) ? null : state.account_cart.items.map(
                i => i.itemID
            )

            const reqsend = {
                accountid           : state.accountinfo.id,
                items_ids           : items_ids,
                domain              : state.domain,
                account_rules       : state.accountinfo.rules
            }

            await fetch( using_urls.getitemsbyid, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    commit('save_account_cart_item_detail', data.itemsdetails)
                })
                .catch( error => console.error('Error:', error));

        },

        async satis_get_latest_cart_details({state,commit,getters}, payload)
        {
            // var items_ids_satis_cart = state['satis_cart'].items.map(
            //     i => i.itemID
            // )

            // const items_ids_satis_cust_cart = state['satis_cust_cart'].items.map(
            //     i => i.itemID
            // )

            // console.log('satis cart & cust cart ')
            if(payload==null || !('items' in payload) || payload.items.length == 0)
            {
                return
            }

            

            const items_ids_satis_cart = payload.items.map(
                i => i.itemID
            )

            // console.log('satis items ids ')
            // console.dir(items_ids_satis_cart)



            // items_ids_satis_cart.concat(items_ids_satis_cust_cart)
            // console.log('satis items ids ')
            // console.dir(items_ids_satis_cart)


            const reqsend = {
                accountid           : state.selected_company._id,
                items_ids           : items_ids_satis_cart,
                domain              : state.satis_account.domain,
                account_rules        : state.selected_company.rules
            }

            await fetch( using_urls.getitemsbyid, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    commit('save_account_cart_item_detail', data.itemsdetails)
                })
                .catch( error => console.error('Error:', error));

        },

        async complete_order({state, dispatch ,commit, getters}, payload)
        {   
            const acc_cart          = state   ['account_cart']
            const settings          = state   ['settings']
            const item_details      = state   ['account_item_details']
            const acc_info      = state   ['accountinfo']
            const discount_5_       = await dispatch('get_discount5')
         
            // console.log("payload:" + payload.length)

            // console.log("payload:" + JSON.stringify(payload))

            // if (payload.length == 0){
            //     console.log("payload:" + JSON.stringify(payload))
            // }


    if (!acc_cart) {
        console.error('Error: account_cart is undefined');
        return;
    }

    const cart_items        = acc_cart['items']

    if (!cart_items) {
        console.error('Error: account_cart.items is undefined');
        return;
    }

            const order_no          = payload.order_no
            const ErrorMsg          = payload.ErrorMsg
            const payStatus         = payload.payStatus

            var new_arr = []
            for(let i = 0; i <cart_items.length; i++ )
            {   
                if(!cart_items[i].checked)
                {
                    continue
                }
                var item_for_list = {}
                var detail_item   = {}
                for(let j=0; j<item_details.length; j++)
                {
                    if( cart_items[i].itemID == item_details[j]._id )
                    {
                        detail_item = item_details[j]
                        break
                    }

                }

                const rules        = (detail_item != {}) ? detail_item['rules'] : {}

                //console.log("complete_order:" + cart_items[i].name + " :: state.account_cart.payment_method: " + state.account_cart.payment_method+ " :: item_for_list['discount_5']: " + item_for_list['discount_5'] + " :: rules:" + rules)

                if(rules == {})
                {continue}

                item_for_list['discount_1'] = 0 // ? müsteri iskontosu
                item_for_list['discount_2'] = 0 // ? müsteri iskontosu
                item_for_list['discount_3'] = 0 // ? kampanya iskontosu
                item_for_list['discount_4'] = 0 // ? dbs iskontosu
                item_for_list['discount_5'] = discount_5_ // ? k.karti/havale iskontosu

                item_for_list['listprice_lcy'] = detail_item.listprices || 0
                
                item_for_list['curr']           = detail_item.listprices_curr || " "
                item_for_list['curr_rate']      = await dispatch('get_kurr', item_for_list['curr'])

                item_for_list['qty']            = cart_items[i].qty
                item_for_list['itemID']         = cart_items[i].itemID
                item_for_list['name']           = cart_items[i].name
                item_for_list['itemno']         = cart_items[i].itemno
                item_for_list['olditemno']      = detail_item.olditemno
                item_for_list['nameSEO']        = cart_items[i].nameSEO

                item_for_list['desi']           = cart_items[i].desi
                item_for_list['imageseo']       = detail_item.imageseo
                item_for_list['image']          = (detail_item.images == undefined) ? null : detail_item.images[0]
                item_for_list['return_qty']     = cart_items[i].return_qty || 0

                item_for_list['tax_rate']       = Number(detail_item.taxrate)

                item_for_list['price']          = cart_items[i].price

                // Fiyatlandirma yapiliyor ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                var retthis           = 0
                var net_price_camp    = 0
                var net_price_private = 0

                var listprices        = detail_item.listprices || 0
                var listprices_curr   = detail_item.listprices_curr || " "

                var calcthis          = 0
                var calcthis_private  = 0
                var calcthis1         = 0
                var calcthis2         = 0
                var price_curr        = " "
                var price_curr1       = " "
                var price_curr2       = " "
                var curr_rate         = 0
                var curr_rate1        = 0
                var curr_rate2        = 0
                var return_rules       = " "
                var return_rules1      = " "
                var return_rules2      = " "
                var discount_1        = 0
                var discount_2        = 0
                var discount_3        = 0
                var discount_4        = 0
                var discount_1_1      = 0
                var discount_2_1      = 0
                var discount_3_1      = 0
                var discount_4_1      = 0
                var discount_1_2      = 0
                var discount_2_2      = 0
                var discount_3_2      = 0
                var discount_4_2      = 0

                var vendorprices      = detail_item.prices || {}

                if ((settings.poolprice_view == true) && (vendorprices != null)){
                  listprices = await dispatch('get_poolprice', vendorprices)
                  listprices_curr = " "
                }

                var CampDisc  = rules.filter((i) => { return i.type == 'iKM'}) // ? Kampanya iskontosu (1)
                var CusDisc   = rules.filter((i) => { return i.type == 'iMS'}) // ? Müşteri iskontosu (2)
                var CusNet    = rules.filter((i) => { return i.type == 'NTF'}) // ? İskontolar Hariç Tutulan Müşteri Fiyatı (3.a)
                var CusPrice  = rules.filter((i) => { return i.type == 'iDF'}) // ? İskontolar Dahil Edilen Müşteri Fiyatı (3.b)
                var CampNet   = rules.filter((i) => { return i.type == 'NTK'}) // ? İskontolar Hariç Tutulan Kampanyalı Fiyat (4.a)
                var CampPrice = rules.filter((i) => { return i.type == 'iDK'}) // ? İskontolar Dahil Edilen Kampanyalı Fiyat (4.b)
                var CustProfit= rules.filter((i) => { return i.type == 'MKM'}) // ? Müşteri Kar Marjı (5)


                if (CusNet[0] != undefined){ // Net fiyat varsa --------------------------------------------------------------------------------------------------
                    calcthis_private           = Number(CusNet[0].value[0]) || 0
                    price_curr                 = CusNet[0].curr  || " "
                    // item_for_list['price']     = calcthis_private
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])
                    if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private

                } else if (CusPrice[0] != undefined){ // Musteri net fiyat yoksa iskontolar dahil musteri fiyatina bakilacak ----------------------------------------
                    calcthis_private           = Number(CusPrice[0].value[0]) || 0
                    price_curr                 = CusPrice[0].curr  || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu                
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                    
                } else { // Net ve iskontolar dahil musteri fiyatı yoksa liste fiyati uzerinden musteri iskontolari dusulmus fiyatina bakilacak -----------------------------------
                    calcthis_private = listprices      || 0
                    price_curr       = listprices_curr || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                }

                // console.log("price_curr: " + price_curr + " :: calcthis_private: " + calcthis_private)

                net_price_private = calcthis_private * await dispatch('get_kurr', price_curr); // tl tutara cevriliyor

                // console.log("net_price_private: " + net_price_private)

                retthis = net_price_private // Pesin ve taksitli fiyat hesaplamalari icin once en dusuk fiyat aliniyor

                // B Stok varsa kampanyali fiyatlar hesaplaniyor
                if (detail_item.inventory > 0) {
                    // B Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor
                    if (CampNet[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampNet[0].value[0]) || 0;
                        price_curr1 = CampNet[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        discount_1_1 = 0
                        discount_2_1 = 0
                        discount_3_1 = 0
                        discount_4_1 = 0
                        if (CampNet[0].tag.indexOf('€') > 0){
                            var ret_rules = CampNet[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                        }
                    } else if (CampPrice[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampPrice[0].value[0]) || 0;
                        price_curr1 = CampPrice[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        if (CampPrice[0].tag.indexOf('€') > 0){
                            var ret_rules = CampPrice[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[0]; discount_1_1 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[1]; discount_2_1 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[2]; discount_3_1 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                            // if (CusDisc[0].value[4] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);  }
                        }
                    }
                    // E Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor

                    // B Kampanya iskontosu hesaplaniyor
                    if ((CampDisc[0] != undefined) && (CusNet[0] == undefined)) { // Kampanya iskontoları dahil fiyatlar icin
                        calcthis2   = (CusPrice[0] != undefined) ? (Number(CusPrice[0].value[0]) || 0)   : (listprices     || 0); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        price_curr2 = (CusPrice[0] != undefined) ? (CusPrice[0].curr  || " ") : (listprices_curr || " "); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        curr_rate2  = await dispatch('get_kurr', price_curr2)
                        if (CampDisc[0].tag.indexOf('€') > 0){
                            var ret_rules = CampDisc[0].tag.split('€');
                            return_rules2 = ret_rules[1];
                        }

                        if (CampDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CampDisc[0].value[2]; discount_3_2 = 100 - Number(CampDisc[0].value[2]);  } // ? kampanya iskontosu
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[0]; discount_1_2 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[1]; discount_2_2 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[2]; discount_3_2 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                            }
                        }
                    }
                    // E Kampanya iskontosu hesaplaniyor

                    // B Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    if ((calcthis1 != 0) && (calcthis1 < calcthis2)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis2 != 0) && (calcthis2 < calcthis1)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    } else if ((calcthis1 != 0) && (calcthis2 == 0)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis1 == 0) && (calcthis2 != 0)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    }
                    // E Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    
                    net_price_camp = calcthis * await dispatch('get_kurr', price_curr); // İskontolu fiyat tl tutara cevriliyor

                    if ((calcthis1 != 0) || (calcthis2 != 0)){
                        if ((net_price_camp < net_price_private) || (net_price_private == 0)){ //20.12.2022 - degisiklik yapildi
                            retthis = net_price_camp
                            // item_for_list['campain_code'] = return_tags
                            item_for_list['price']      = calcthis
                            item_for_list['curr']       = price_curr
                            item_for_list['curr_rate']  = await dispatch('get_kurr', item_for_list['curr'])
                            item_for_list['discount_1'] = discount_1
                            item_for_list['discount_2'] = discount_2
                            item_for_list['discount_3'] = discount_3
                            item_for_list['discount_4'] = discount_4
                        } else {
                            return_rules = " "
                        }
                    } else {
                        return_rules = " "
                    }
                }

                item_for_list['campain_code'] = return_rules

                if (acc_cart.payment_method != undefined) { //Eger K.Karti veya Havale/EFT secildi ise
                    if ((acc_cart.payment_method == "1")||(acc_cart.payment_method == "2")) {
                        item_for_list['price'] = item_for_list['price'] * 0.01 * ((100 - item_for_list['discount_5']));
                    }
                }
                // console.log(":::::: new price: " + item_for_list['price'] + " :: curr_rate: " + item_for_list['curr_rate'])

                var b2b_match_service_status = false;
                if (state.arsal_vendor_info.b2b_match_service_status[state.domain] != undefined){
                  if (state.arsal_vendor_info.b2b_match_service_status[state.domain].status != undefined){
                    b2b_match_service_status = state.arsal_vendor_info.b2b_match_service_status[state.domain].status;
                  }
                }
        
                if ((CustProfit[0] != undefined) && ((b2b_match_service_status == false) || ((b2b_match_service_status == true) && (detail_item.productmatch == true)))){
                  if (Number(CustProfit[0].value[0]) != 100){  item_for_list['price'] = item_for_list['price'] * 0.01 * Number(CustProfit[0].value[0]); } // ? Müşteri Kar Marjı (5)
                }

                item_for_list['price_lcy']      = item_for_list['price'] * item_for_list['curr_rate']
                item_for_list['lineamount_lcy'] = item_for_list['qty'] * item_for_list['price_lcy'] 
                item_for_list['linetax_lcy']    = item_for_list['lineamount_lcy'] * (item_for_list['tax_rate'] / 100)
                item_for_list['linetotal_lcy']  = item_for_list['lineamount_lcy'] + item_for_list['linetax_lcy']

                new_arr.push(item_for_list)
            }

          

            const reqsend = {
                accountId         : state.accountinfo.id,
                cust_name         : state.accountinfo.name,
                cust_no           : state.accountinfo.no,
                sales_person_code : state.accountinfo.salespersoncode,
                cust_email        : state.accountinfo.email,
                domain            : state.domain,
                account_cart      : state.account_cart,
                items_list        : new_arr,
                order_no          : order_no,
                salesperson_email : state.salesperson.email, //  TB yok appuser hatyasi
            }

            //console.log("complete_order:reqsend: " + JSON.stringify(reqsend))
     
            await fetch( using_urls.complete_order, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    const newcart = data.account_cart || [];
                    commit("updatecart", newcart)
                    //dispatch('req_detailed_faves')

                    dispatch('commit_order_no', data.order_no)

                })
                .catch( error => console.error('Error:', error));
        
            

            return
        },

        async complete_order_satis({state, dispatch ,commit, getters}, payload)
        {   
            const acc_cart          = state   ['satis_cart']
            const settings          = state   ['settings']
            const item_details      = state   ['account_item_details']
            const acc_info      = state   ['accountinfo']
            const discount_5_       = await dispatch('get_discount5')
         
            // console.log("payload:" + payload.length)

            // console.log("payload:" + JSON.stringify(payload))

            // if (payload.length == 0){
            //     console.log("payload:" + JSON.stringify(payload))
            // }


    if (!acc_cart) {
        console.error('Error: account_cart is undefined');
        return;
    }

    const cart_items        = acc_cart['items']

    if (!cart_items) {
        console.error('Error: account_cart.items is undefined');
        return;
    }

            const order_no          = payload.order_no
            const ErrorMsg          = payload.ErrorMsg
            const payStatus         = payload.payStatus

            var new_arr = []
            for(let i = 0; i <cart_items.length; i++ )
            {   
                if(!cart_items[i].checked)
                {
                    continue
                }
                var item_for_list = {}
                var detail_item   = {}
                for(let j=0; j<item_details.length; j++)
                {
                    if( cart_items[i].itemID == item_details[j]._id )
                    {
                        detail_item = item_details[j]
                        break
                    }

                }

                const rules        = (detail_item != {}) ? detail_item['rules'] : {}

                //console.log("complete_order:" + cart_items[i].name + " :: state.account_cart.payment_method: " + state.account_cart.payment_method+ " :: item_for_list['discount_5']: " + item_for_list['discount_5'] + " :: rules:" + rules)

                if(rules == {})
                {continue}

                item_for_list['discount_1'] = 0 // ? müsteri iskontosu
                item_for_list['discount_2'] = 0 // ? müsteri iskontosu
                item_for_list['discount_3'] = 0 // ? kampanya iskontosu
                item_for_list['discount_4'] = 0 // ? dbs iskontosu
                item_for_list['discount_5'] = discount_5_ // ? k.karti/havale iskontosu

                item_for_list['listprice_lcy'] = detail_item.listprices || 0
                
                item_for_list['curr']           = detail_item.listprices_curr || " "
                item_for_list['curr_rate']      = await dispatch('get_kurr', item_for_list['curr'])

                item_for_list['qty']            = cart_items[i].qty
                item_for_list['itemID']         = cart_items[i].itemID
                item_for_list['name']           = cart_items[i].name
                item_for_list['itemno']         = cart_items[i].itemno
                item_for_list['olditemno']      = detail_item.olditemno
                item_for_list['nameSEO']        = cart_items[i].nameSEO

                item_for_list['desi']           = cart_items[i].desi
                item_for_list['imageseo']       = detail_item.imageseo
                item_for_list['image']          = (detail_item.images == undefined) ? null : detail_item.images[0]
                item_for_list['return_qty']     = cart_items[i].return_qty || 0

                item_for_list['tax_rate']       = Number(detail_item.taxrate)

                item_for_list['price']          = cart_items[i].price

                // Fiyatlandirma yapiliyor ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                var retthis           = 0
                var net_price_camp    = 0
                var net_price_private = 0

                var listprices        = detail_item.listprices || 0
                var listprices_curr   = detail_item.listprices_curr || " "

                var calcthis          = 0
                var calcthis_private  = 0
                var calcthis1         = 0
                var calcthis2         = 0
                var price_curr        = " "
                var price_curr1       = " "
                var price_curr2       = " "
                var curr_rate         = 0
                var curr_rate1        = 0
                var curr_rate2        = 0
                var return_rules       = " "
                var return_rules1      = " "
                var return_rules2      = " "
                var discount_1        = 0
                var discount_2        = 0
                var discount_3        = 0
                var discount_4        = 0
                var discount_1_1      = 0
                var discount_2_1      = 0
                var discount_3_1      = 0
                var discount_4_1      = 0
                var discount_1_2      = 0
                var discount_2_2      = 0
                var discount_3_2      = 0
                var discount_4_2      = 0

                var vendorprices      = detail_item.prices || {}

                if ((settings.poolprice_view == true) && (vendorprices != null)){
                  listprices = await dispatch('get_poolprice', vendorprices)
                  listprices_curr = " "
                }

                var CampDisc  = rules.filter((i) => { return i.type == 'iKM'}) // ? Kampanya iskontosu (1)
                var CusDisc   = rules.filter((i) => { return i.type == 'iMS'}) // ? Müşteri iskontosu (2)
                var CusNet    = rules.filter((i) => { return i.type == 'NTF'}) // ? İskontolar Hariç Tutulan Müşteri Fiyatı (3.a)
                var CusPrice  = rules.filter((i) => { return i.type == 'iDF'}) // ? İskontolar Dahil Edilen Müşteri Fiyatı (3.b)
                var CampNet   = rules.filter((i) => { return i.type == 'NTK'}) // ? İskontolar Hariç Tutulan Kampanyalı Fiyat (4.a)
                var CampPrice = rules.filter((i) => { return i.type == 'iDK'}) // ? İskontolar Dahil Edilen Kampanyalı Fiyat (4.b)
                var CustProfit= rules.filter((i) => { return i.type == 'MKM'}) // ? Müşteri Kar Marjı (5)


                if (CusNet[0] != undefined){ // Net fiyat varsa --------------------------------------------------------------------------------------------------
                    calcthis_private           = Number(CusNet[0].value[0]) || 0
                    price_curr                 = CusNet[0].curr  || " "
                    // item_for_list['price']     = calcthis_private
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])
                    if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private

                } else if (CusPrice[0] != undefined){ // Musteri net fiyat yoksa iskontolar dahil musteri fiyatina bakilacak ----------------------------------------
                    calcthis_private           = Number(CusPrice[0].value[0]) || 0
                    price_curr                 = CusPrice[0].curr  || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu                
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                    
                } else { // Net ve iskontolar dahil musteri fiyatı yoksa liste fiyati uzerinden musteri iskontolari dusulmus fiyatina bakilacak -----------------------------------
                    calcthis_private = listprices      || 0
                    price_curr       = listprices_curr || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                }

                // console.log("price_curr: " + price_curr + " :: calcthis_private: " + calcthis_private)

                net_price_private = calcthis_private * await dispatch('get_kurr', price_curr); // tl tutara cevriliyor

                // console.log("net_price_private: " + net_price_private)

                retthis = net_price_private // Pesin ve taksitli fiyat hesaplamalari icin once en dusuk fiyat aliniyor

                // B Stok varsa kampanyali fiyatlar hesaplaniyor
                if (detail_item.inventory > 0) {
                    // B Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor
                    if (CampNet[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampNet[0].value[0]) || 0;
                        price_curr1 = CampNet[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        discount_1_1 = 0
                        discount_2_1 = 0
                        discount_3_1 = 0
                        discount_4_1 = 0
                        if (CampNet[0].tag.indexOf('€') > 0){
                            var ret_rules = CampNet[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                        }
                    } else if (CampPrice[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampPrice[0].value[0]) || 0;
                        price_curr1 = CampPrice[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        if (CampPrice[0].tag.indexOf('€') > 0){
                            var ret_rules = CampPrice[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[0]; discount_1_1 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[1]; discount_2_1 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[2]; discount_3_1 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                            // if (CusDisc[0].value[4] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);  }
                        }
                    }
                    // E Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor

                    // B Kampanya iskontosu hesaplaniyor
                    if ((CampDisc[0] != undefined) && (CusNet[0] == undefined)) { // Kampanya iskontoları dahil fiyatlar icin
                        calcthis2   = (CusPrice[0] != undefined) ? (Number(CusPrice[0].value[0]) || 0)   : (listprices     || 0); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        price_curr2 = (CusPrice[0] != undefined) ? (CusPrice[0].curr  || " ") : (listprices_curr || " "); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        curr_rate2  = await dispatch('get_kurr', price_curr2)
                        if (CampDisc[0].tag.indexOf('€') > 0){
                            var ret_rules = CampDisc[0].tag.split('€');
                            return_rules2 = ret_rules[1];
                        }

                        if (CampDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CampDisc[0].value[2]; discount_3_2 = 100 - Number(CampDisc[0].value[2]);  } // ? kampanya iskontosu
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[0]; discount_1_2 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[1]; discount_2_2 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[2]; discount_3_2 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                            }
                        }
                    }
                    // E Kampanya iskontosu hesaplaniyor

                    // B Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    if ((calcthis1 != 0) && (calcthis1 < calcthis2)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis2 != 0) && (calcthis2 < calcthis1)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    } else if ((calcthis1 != 0) && (calcthis2 == 0)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis1 == 0) && (calcthis2 != 0)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    }
                    // E Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    
                    net_price_camp = calcthis * await dispatch('get_kurr', price_curr); // İskontolu fiyat tl tutara cevriliyor

                    if ((calcthis1 != 0) || (calcthis2 != 0)){
                        if ((net_price_camp < net_price_private) || (net_price_private == 0)){ //20.12.2022 - degisiklik yapildi
                            retthis = net_price_camp
                            // item_for_list['campain_code'] = return_tags
                            item_for_list['price']      = calcthis
                            item_for_list['curr']       = price_curr
                            item_for_list['curr_rate']  = await dispatch('get_kurr', item_for_list['curr'])
                            item_for_list['discount_1'] = discount_1
                            item_for_list['discount_2'] = discount_2
                            item_for_list['discount_3'] = discount_3
                            item_for_list['discount_4'] = discount_4
                        } else {
                            return_rules = " "
                        }
                    } else {
                        return_rules = " "
                    }
                }

                item_for_list['campain_code'] = return_rules

                if (acc_cart.payment_method != undefined) { //Eger K.Karti veya Havale/EFT secildi ise
                    if ((acc_cart.payment_method == "1")||(acc_cart.payment_method == "2")) {
                        item_for_list['price'] = item_for_list['price'] * 0.01 * ((100 - item_for_list['discount_5']));
                    }
                }
                // console.log(":::::: new price: " + item_for_list['price'] + " :: curr_rate: " + item_for_list['curr_rate'])

                var b2b_match_service_status = false;
                if (state.arsal_vendor_info.b2b_match_service_status[state.domain] != undefined){
                  if (state.arsal_vendor_info.b2b_match_service_status[state.domain].status != undefined){
                    b2b_match_service_status = state.arsal_vendor_info.b2b_match_service_status[state.domain].status;
                  }
                }
        
                if ((CustProfit[0] != undefined) && ((b2b_match_service_status == false) || ((b2b_match_service_status == true) && (detail_item.productmatch == true)))){
                  if (Number(CustProfit[0].value[0]) != 100){  item_for_list['price'] = item_for_list['price'] * 0.01 * Number(CustProfit[0].value[0]); } // ? Müşteri Kar Marjı (5)
                }

                item_for_list['price_lcy']      = item_for_list['price'] * item_for_list['curr_rate']
                item_for_list['lineamount_lcy'] = item_for_list['qty'] * item_for_list['price_lcy'] 
                item_for_list['linetax_lcy']    = item_for_list['lineamount_lcy'] * (item_for_list['tax_rate'] / 100)
                item_for_list['linetotal_lcy']  = item_for_list['lineamount_lcy'] + item_for_list['linetax_lcy']

                new_arr.push(item_for_list)
            }

            var accountId;
            var cust_name;
            var cust_no;
            var sales_person_code;
            var cust_email;
            var salesperson_email;
            if(acc_info == undefined){
                 accountId = acc_cart.cust_id;
                 cust_name = acc_cart.cust_name;
                 cust_no = acc_cart.cust_no;
                 sales_person_code = state.satis_account.name;
                 cust_email = acc_cart.cust_email;
    
            }else{
                accountId = acc_info.id;
                cust_name = acc_info.name;
                cust_no = acc_info.no;
                sales_person_code = acc_info.salespersoncode;
                cust_email = acc_info.email;
            }


            if(state.salesperson == undefined){
                salesperson_email=state.satis_account.email;
            }else{
                salesperson_email=state.salesperson.email;
            }

            const reqsend = {
                accountId         : accountId,
                cust_name         : cust_name,
                cust_no           : cust_no,
                sales_person_code : sales_person_code,
                cust_email        : cust_email,
                domain            : state.domain,
                account_cart      : acc_cart,
                items_list        : new_arr,
                order_no          : order_no,
                salesperson_email : salesperson_email , //  TB yok appuser hatyasi
            }

            //console.log("complete_order:reqsend: " + JSON.stringify(reqsend))
        
            await fetch( using_urls.complete_order_satis, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    const newcart = data.account_cart || [];
                    commit("updatecart_satis", newcart)
                    //dispatch('req_detailed_faves')

                    dispatch('commit_order_no', data.order_no)

                })
                .catch( error => console.error('Error:', error));
       
            

            return
        },

        async complete_order_cust({state, dispatch ,commit, getters}, payload)
        {   
            const acc_cart          = state   ['satis_cust_cart']
            const settings          = state   ['settings']
            const item_details      = state   ['account_item_details']
            const acc_info      = state   ['accountinfo']
            const discount_5_       = await dispatch('get_discount5')
         
            // console.log("payload:" + payload.length)

            // console.log("payload:" + JSON.stringify(payload))

            // if (payload.length == 0){
            //     console.log("payload:" + JSON.stringify(payload))
            // }


    if (!acc_cart) {
        console.error('Error: account_cart is undefined');
        return;
    }

    const cart_items        = acc_cart['items']

    if (!cart_items) {
        console.error('Error: account_cart.items is undefined');
        return;
    }

            const order_no          = payload.order_no
            const ErrorMsg          = payload.ErrorMsg
            const payStatus         = payload.payStatus

            var new_arr = []
            for(let i = 0; i <cart_items.length; i++ )
            {   
                if(!cart_items[i].checked)
                {
                    continue
                }
                var item_for_list = {}
                var detail_item   = {}
                for(let j=0; j<item_details.length; j++)
                {
                    if( cart_items[i].itemID == item_details[j]._id )
                    {
                        detail_item = item_details[j]
                        break
                    }

                }

                const rules        = (detail_item != {}) ? detail_item['rules'] : {}

                //console.log("complete_order:" + cart_items[i].name + " :: state.account_cart.payment_method: " + state.account_cart.payment_method+ " :: item_for_list['discount_5']: " + item_for_list['discount_5'] + " :: rules:" + rules)

                if(rules == {})
                {continue}

                item_for_list['discount_1'] = 0 // ? müsteri iskontosu
                item_for_list['discount_2'] = 0 // ? müsteri iskontosu
                item_for_list['discount_3'] = 0 // ? kampanya iskontosu
                item_for_list['discount_4'] = 0 // ? dbs iskontosu
                item_for_list['discount_5'] = discount_5_ // ? k.karti/havale iskontosu

                item_for_list['listprice_lcy'] = detail_item.listprices || 0
                
                item_for_list['curr']           = detail_item.listprices_curr || " "
                item_for_list['curr_rate']      = await dispatch('get_kurr', item_for_list['curr'])

                item_for_list['qty']            = cart_items[i].qty
                item_for_list['itemID']         = cart_items[i].itemID
                item_for_list['name']           = cart_items[i].name
                item_for_list['itemno']         = cart_items[i].itemno
                item_for_list['olditemno']      = detail_item.olditemno
                item_for_list['nameSEO']        = cart_items[i].nameSEO

                item_for_list['desi']           = cart_items[i].desi
                item_for_list['imageseo']       = detail_item.imageseo
                item_for_list['image']          = (detail_item.images == undefined) ? null : detail_item.images[0]
                item_for_list['return_qty']     = cart_items[i].return_qty || 0

                item_for_list['tax_rate']       = Number(detail_item.taxrate)

                item_for_list['price']          = cart_items[i].price

                // Fiyatlandirma yapiliyor ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                var retthis           = 0
                var net_price_camp    = 0
                var net_price_private = 0

                var listprices        = detail_item.listprices || 0
                var listprices_curr   = detail_item.listprices_curr || " "

                var calcthis          = 0
                var calcthis_private  = 0
                var calcthis1         = 0
                var calcthis2         = 0
                var price_curr        = " "
                var price_curr1       = " "
                var price_curr2       = " "
                var curr_rate         = 0
                var curr_rate1        = 0
                var curr_rate2        = 0
                var return_rules       = " "
                var return_rules1      = " "
                var return_rules2      = " "
                var discount_1        = 0
                var discount_2        = 0
                var discount_3        = 0
                var discount_4        = 0
                var discount_1_1      = 0
                var discount_2_1      = 0
                var discount_3_1      = 0
                var discount_4_1      = 0
                var discount_1_2      = 0
                var discount_2_2      = 0
                var discount_3_2      = 0
                var discount_4_2      = 0

                var vendorprices      = detail_item.prices || {}

                if ((settings.poolprice_view == true) && (vendorprices != null)){
                  listprices = await dispatch('get_poolprice', vendorprices)
                  listprices_curr = " "
                }

                var CampDisc  = rules.filter((i) => { return i.type == 'iKM'}) // ? Kampanya iskontosu (1)
                var CusDisc   = rules.filter((i) => { return i.type == 'iMS'}) // ? Müşteri iskontosu (2)
                var CusNet    = rules.filter((i) => { return i.type == 'NTF'}) // ? İskontolar Hariç Tutulan Müşteri Fiyatı (3.a)
                var CusPrice  = rules.filter((i) => { return i.type == 'iDF'}) // ? İskontolar Dahil Edilen Müşteri Fiyatı (3.b)
                var CampNet   = rules.filter((i) => { return i.type == 'NTK'}) // ? İskontolar Hariç Tutulan Kampanyalı Fiyat (4.a)
                var CampPrice = rules.filter((i) => { return i.type == 'iDK'}) // ? İskontolar Dahil Edilen Kampanyalı Fiyat (4.b)
                var CustProfit= rules.filter((i) => { return i.type == 'MKM'}) // ? Müşteri Kar Marjı (5)


                if (CusNet[0] != undefined){ // Net fiyat varsa --------------------------------------------------------------------------------------------------
                    calcthis_private           = Number(CusNet[0].value[0]) || 0
                    price_curr                 = CusNet[0].curr  || " "
                    // item_for_list['price']     = calcthis_private
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])
                    if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private

                } else if (CusPrice[0] != undefined){ // Musteri net fiyat yoksa iskontolar dahil musteri fiyatina bakilacak ----------------------------------------
                    calcthis_private           = Number(CusPrice[0].value[0]) || 0
                    price_curr                 = CusPrice[0].curr  || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu                
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                    
                } else { // Net ve iskontolar dahil musteri fiyatı yoksa liste fiyati uzerinden musteri iskontolari dusulmus fiyatina bakilacak -----------------------------------
                    calcthis_private = listprices      || 0
                    price_curr       = listprices_curr || " "
                    item_for_list['curr']      = price_curr
                    item_for_list['curr_rate'] = await dispatch('get_kurr', item_for_list['curr'])

                    if (CusDisc[0] != undefined){
                        if (CusDisc[0].value[0] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[0]; item_for_list['discount_1'] = 100 - Number(CusDisc[0].value[0]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[1] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[1]; item_for_list['discount_2'] = 100 - Number(CusDisc[0].value[1]); } // ? müsteri iskontosu
                        if (CusDisc[0].value[2] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[2]; item_for_list['discount_3'] = 100 - Number(CusDisc[0].value[2]); } // ? müsteri / kampanya iskontosu
                        // if (CusDisc[0].value[4] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]); }
                        if (acc_cart.payment_method != undefined) {
                            if (acc_cart.payment_method== "0") {
                                if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                            }
                        } else {
                            if (CusDisc[0].value[3] != 100){  calcthis_private = calcthis_private * 0.01 * CusDisc[0].value[3]; item_for_list['discount_4'] = 100 - Number(CusDisc[0].value[3]); } // ? dbs iskontosu
                        }
                    }
                    item_for_list['price']     = calcthis_private
                }

                // console.log("price_curr: " + price_curr + " :: calcthis_private: " + calcthis_private)

                net_price_private = calcthis_private * await dispatch('get_kurr', price_curr); // tl tutara cevriliyor

                // console.log("net_price_private: " + net_price_private)

                retthis = net_price_private // Pesin ve taksitli fiyat hesaplamalari icin once en dusuk fiyat aliniyor

                // B Stok varsa kampanyali fiyatlar hesaplaniyor
                if (detail_item.inventory > 0) {
                    // B Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor
                    if (CampNet[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampNet[0].value[0]) || 0;
                        price_curr1 = CampNet[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        discount_1_1 = 0
                        discount_2_1 = 0
                        discount_3_1 = 0
                        discount_4_1 = 0
                        if (CampNet[0].tag.indexOf('€') > 0){
                            var ret_rules = CampNet[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){ // Varsa musteri Dbs iskontosu dahil ediliyor
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                        }
                    } else if (CampPrice[0] != undefined) { // Dbs iskontosu dahil Kampanya net fiyat
                        calcthis1   = Number(CampPrice[0].value[0]) || 0;
                        price_curr1 = CampPrice[0].curr  || " ";
                        curr_rate1  = await dispatch('get_kurr', price_curr1)
                        if (CampPrice[0].tag.indexOf('€') > 0){
                            var ret_rules = CampPrice[0].tag.split('€');
                            return_rules1 = ret_rules[1];
                        }
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[0]; discount_1_1 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[1]; discount_2_1 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[2]; discount_3_1 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[3]; discount_4_1 = 100 - Number(CusDisc[0].value[3]);  }
                            }
                            // if (CusDisc[0].value[4] != 100){  calcthis1 = calcthis1 * 0.01 * CusDisc[0].value[4]; item_for_list['discount_5'] = 100 - Number(CusDisc[0].value[4]);  }
                        }
                    }
                    // E Kampanya net fiyati (iskontolu/iskontosuz) hesaplaniyor

                    // B Kampanya iskontosu hesaplaniyor
                    if ((CampDisc[0] != undefined) && (CusNet[0] == undefined)) { // Kampanya iskontoları dahil fiyatlar icin
                        calcthis2   = (CusPrice[0] != undefined) ? (Number(CusPrice[0].value[0]) || 0)   : (listprices     || 0); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        price_curr2 = (CusPrice[0] != undefined) ? (CusPrice[0].curr  || " ") : (listprices_curr || " "); // Musteri net fiyat yoksa liste fiyatı uzerinden bakilacak
                        curr_rate2  = await dispatch('get_kurr', price_curr2)
                        if (CampDisc[0].tag.indexOf('€') > 0){
                            var ret_rules = CampDisc[0].tag.split('€');
                            return_rules2 = ret_rules[1];
                        }

                        if (CampDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CampDisc[0].value[2]; discount_3_2 = 100 - Number(CampDisc[0].value[2]);  } // ? kampanya iskontosu
                        if (CusDisc[0] != undefined){
                            if (CusDisc[0].value[0] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[0]; discount_1_2 = 100 - Number(CusDisc[0].value[0]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[1] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[1]; discount_2_2 = 100 - Number(CusDisc[0].value[1]);  } // ? müsteri iskontosu
                            if (CusDisc[0].value[2] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[2]; discount_3_2 = 100 - Number(CusDisc[0].value[2]);  } // ? müsteri / kampanya iskontosu
                            if (acc_cart.payment_method != undefined) {
                                if (acc_cart.payment_method == "0") {
                                    if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                                }
                            } else {
                                if (CusDisc[0].value[3] != 100){  calcthis2 = calcthis2 * 0.01 * CusDisc[0].value[3]; discount_4_2 = 100 - Number(CusDisc[0].value[3]);  } // ? dbs iskontosu
                            }
                        }
                    }
                    // E Kampanya iskontosu hesaplaniyor

                    // B Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    if ((calcthis1 != 0) && (calcthis1 < calcthis2)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis2 != 0) && (calcthis2 < calcthis1)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    } else if ((calcthis1 != 0) && (calcthis2 == 0)) {
                        calcthis     = calcthis1
                        price_curr   = price_curr1
                        return_rules  = return_rules1
                        curr_rate    = curr_rate1
                        discount_1   = discount_1_1
                        discount_2   = discount_2_1
                        discount_3   = discount_3_1
                        discount_4   = discount_4_1
                    } else if ((calcthis1 == 0) && (calcthis2 != 0)) {
                        calcthis     = calcthis2
                        price_curr   = price_curr2
                        return_rules  = return_rules2
                        curr_rate    = curr_rate2
                        discount_1   = discount_1_2
                        discount_2   = discount_2_2
                        discount_3   = discount_3_2
                        discount_4   = discount_4_2
                    }
                    // E Eger iki kampanya turu de varsa fiyat karsilastirmasi yapiliyor
                    
                    net_price_camp = calcthis * await dispatch('get_kurr', price_curr); // İskontolu fiyat tl tutara cevriliyor

                    if ((calcthis1 != 0) || (calcthis2 != 0)){
                        if ((net_price_camp < net_price_private) || (net_price_private == 0)){ //20.12.2022 - degisiklik yapildi
                            retthis = net_price_camp
                            // item_for_list['campain_code'] = return_tags
                            item_for_list['price']      = calcthis
                            item_for_list['curr']       = price_curr
                            item_for_list['curr_rate']  = await dispatch('get_kurr', item_for_list['curr'])
                            item_for_list['discount_1'] = discount_1
                            item_for_list['discount_2'] = discount_2
                            item_for_list['discount_3'] = discount_3
                            item_for_list['discount_4'] = discount_4
                        } else {
                            return_rules = " "
                        }
                    } else {
                        return_rules = " "
                    }
                }

                item_for_list['campain_code'] = return_rules

                if (acc_cart.payment_method != undefined) { //Eger K.Karti veya Havale/EFT secildi ise
                    if ((acc_cart.payment_method == "1")||(acc_cart.payment_method == "2")) {
                        item_for_list['price'] = item_for_list['price'] * 0.01 * ((100 - item_for_list['discount_5']));
                    }
                }
                // console.log(":::::: new price: " + item_for_list['price'] + " :: curr_rate: " + item_for_list['curr_rate'])

                var b2b_match_service_status = false;
                if (state.arsal_vendor_info.b2b_match_service_status[state.domain] != undefined){
                  if (state.arsal_vendor_info.b2b_match_service_status[state.domain].status != undefined){
                    b2b_match_service_status = state.arsal_vendor_info.b2b_match_service_status[state.domain].status;
                  }
                }
        
                if ((CustProfit[0] != undefined) && ((b2b_match_service_status == false) || ((b2b_match_service_status == true) && (detail_item.productmatch == true)))){
                  if (Number(CustProfit[0].value[0]) != 100){  item_for_list['price'] = item_for_list['price'] * 0.01 * Number(CustProfit[0].value[0]); } // ? Müşteri Kar Marjı (5)
                }

                item_for_list['price_lcy']      = item_for_list['price'] * item_for_list['curr_rate']
                item_for_list['lineamount_lcy'] = item_for_list['qty'] * item_for_list['price_lcy'] 
                item_for_list['linetax_lcy']    = item_for_list['lineamount_lcy'] * (item_for_list['tax_rate'] / 100)
                item_for_list['linetotal_lcy']  = item_for_list['lineamount_lcy'] + item_for_list['linetax_lcy']

                new_arr.push(item_for_list)
            }

            var accountId;
            var cust_name;
            var cust_no;
            var sales_person_code;
            var cust_email;
            var salesperson_email;
            if(acc_info == undefined){
                 accountId = acc_cart.accountid;
                 cust_name = acc_cart.cust_name;
                 cust_no = acc_cart.cust_no;
                 sales_person_code = state.satis_account.name;
                 cust_email = acc_cart.cust_email;
    
            }else{
                accountId = acc_info.id;
                cust_name = acc_info.name;
                cust_no = acc_info.no;
                sales_person_code = acc_info.salespersoncode;
                cust_email = acc_info.email;
            }

            if(state.salesperson == undefined){
                salesperson_email=state.satis_account.email;
            }else{
                salesperson_email=state.salesperson.email;
            }

            const reqsend = {
                accountId         : accountId,
                cust_name         : cust_name,
                cust_no           : cust_no,
                sales_person_code : sales_person_code,
                cust_email        : cust_email,
                domain            : state.domain,
                account_cart      : acc_cart,
                items_list        : new_arr,
                order_no          : order_no,
                salesperson_email : salesperson_email , //  TB yok appuser hatyasi
            }

            //console.log("complete_order:reqsend: " + JSON.stringify(reqsend))
        
            await fetch( using_urls.complete_order_cust, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    const newcart = data.account_cart || [];
                    commit("updatecart_cust", newcart)
                    //dispatch('req_detailed_faves')

                    dispatch('commit_order_no', data.order_no)

                })
                .catch( error => console.error('Error:', error));
     
            

            return
        },

        async complete_return({state, commit}, payload)
        {   
            const return_shipping   = payload.return_shipping
            const return_note       = payload.return_note
            const return_items_list = payload.return_items_list

            const reqsend = {
                accountId           : state.accountinfo.id,
                cust_name           : state.accountinfo.name,
                cust_no             : state.accountinfo.no,
                domain              : state.domain,
                salesperson_email   : state.salesperson.email,
                return_note         : return_note,
                return_shipping     : return_shipping,
                return_items_list   : return_items_list,
            }

            // console.log("complete_order:reqsend: " + JSON.stringify(reqsend))
            // return "555555"

            var return_no =""
        
            await fetch( using_urls.complete_return, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    const newreturncart = [];
                    return_no = data.return_no
                    commit("updatereturncart", newreturncart)

                    //dispatch('req_detailed_faves')

                    // dispatch('commit_order_no', data.order_no)

                })
                .catch( error => console.error('Error:', error));

            return return_no
        },


        get_discount5({state, getters})
        {
            try {
                //Havale veya kredi kartina gore ek iskonto aliniyor ----------------------------------------------------------------
                var additionaldisc = 0;
                if (state.account_cart.payment_method != undefined) {
                    if (state.account_cart.payment_method == "1") {
                        additionaldisc = state.settings.payment_eft_rate || 0;
                    } else if (state.account_cart.payment_method == "2") {
                        const bankinfo = state.settings.bank_pos_list.filter(i => { return (i.mainpos_code == state.account_cart.payment_mainpos_code)  })
                        if (bankinfo != undefined) {
                            for (let t = 0; t < bankinfo[0].installments.length; t++) {
                                if (bankinfo[0].installments[t].installment == state.account_cart.payment_installment) {
                                    additionaldisc = bankinfo[0].installments[t].cartdiscount;
                                }
                            }
                        }
                    }
                }
                // console.log("additionaldisc:" + additionaldisc)
                return additionaldisc 
             }  catch { 
                console.log( "additional discount error")
                return 0
             }
        },

        get_kurr({state, getters}, curr_code_in){
            if( curr_code_in.includes('EUR-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('EURO')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexsat)
            }
            if( curr_code_in.includes('USD-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('US DOLLAR')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexsat)
            }
            if( curr_code_in.includes('GBP-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('POUND')
                    }
                ).pop()

                return parseFloat(curr_obj.forexsat)
            }
            // B 28.11.2022  -   MA-MS kur duzenlemesi yapildi
            if( curr_code_in.includes('EUR-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('EURO')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexal)
            }
            if( curr_code_in.includes('USD-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('US DOLLAR')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexal)
            }
            if( curr_code_in.includes('GBP-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('POUND')
                    }
                ).pop()

                return parseFloat(curr_obj.forexal)
            }
            // E 28.11.2022  -   MA-MS kur duzenlemesi yapildi

            return 1
        },

        async get_poolprice({dispatch}, vendorprices){

            var strPriceLCY         = 0
            var strnoCampaignPrice  = 0
            var strCampaignPrice    = 0
            var strStartingDate     = 0
            var strEndingDate       = 0
            var strToday            = Date.parse(new Date())

            // console.log("vendorprices:" + JSON.stringify(vendorprices))
        
            for (const property in vendorprices) {
              for (const sub_property in vendorprices[property]){
                strPriceLCY     = vendorprices[property][sub_property]['Price'] * await dispatch('get_kurr', property)
                strStartingDate = vendorprices[property][sub_property]['StartingDate']
                strEndingDate   = vendorprices[property][sub_property]['EndingDate']
        
                strnoCampaignPrice = ((sub_property == "noCampaign") && ((strPriceLCY < strnoCampaignPrice) || (strnoCampaignPrice == 0)) && (((strEndingDate < 0) && (strToday >= strStartingDate)) || ((strEndingDate >= strToday) && (strToday >= strStartingDate)))) ? strPriceLCY : strnoCampaignPrice
                strCampaignPrice = ((sub_property == "Campaign") && ((strPriceLCY < strnoCampaignPrice) || (strCampaignPrice == 0)) && (((strEndingDate < 0) && (strToday >= strStartingDate)) || ((strEndingDate >= strToday) && (strToday >= strStartingDate)))) ? strPriceLCY : strCampaignPrice
                // console.log("Org. Fiyat:" + vendorprices[property][sub_property]['Price'] +" :: Döviz: " + property + " :: strnoCampaignPrice:" + strnoCampaignPrice +  " :: strCampaignPrice: " + strCampaignPrice + " :: strPriceLCY: " + strPriceLCY)
              }
            }
            return (strnoCampaignPrice > strCampaignPrice) ? strnoCampaignPrice : strCampaignPrice
        },
          
        async search_customers( { state, commit}, payload)
        {   

            const search_string = payload.search_string

            // console.dir(state['satis_account'])

            var reqsend = {
                search_string : search_string,
                user                : state.satis_account.user,
                domain              : state.satis_account.domain,
                name                : state.satis_account.name, 
            }

            await fetch( using_urls.search_customers, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body:  JSON.stringify(reqsend)
                ,
                })
                .then(response => response.json())
                .then(data => {
                    commit('save_customer_search', data.data)
                })
                .catch( error => console.error('Error:', error));




        },
        async change_selected_company( {state, commit,dispatch}, payload)
        {   

            const commit_payload = {
                selected_company_name: payload.selected_company_name,
                selected_company_no  : payload.selected_compnay_no,
                selected_company_id  : payload.selected_company_id,
                selected_company_rules: payload.selected_company_rules,
                data                 : payload.data
            }
            

            commit('save_selected_company', payload)
            // dispatch('act_get_satis_cust_cari', {
            //     isonlyload:0
            // })
            
            dispatch('get_satis_cart_m')
            dispatch('get_satis_cust_cart_m')
            dispatch('act_get_satis_cust_cari') //03.03.2024 added

        },
        async satis_search_item( {state, commit}, payload)
        {

                        
            // CHECK PAYLOAD PAGE TO SEE AMOUNT OF RESULTS LOADED and TOTAL AMOUNT OF POSSIBLE RESULTS
            // IF NOT ALL RESULTES ARE LOADED LOAD CHUCK OF NEW DATA AND PUSH INTO STATE VAR USED 
            // INCREMENT THE AMOUNT OF LOADED RESUKLTS
            // 

            // req = {                              res = {
            //     searchword:                          message:
            //     filtre:                              totaldocnumber:
            //     sort:
            //     numofreturn:
            //     startofresultreturn:
            // }    }

            const isonlyload            = payload.isonlyload
            const page                  = payload.page
            const numofreturn           = 25

            const doesloadedexist       = page in state.loadedpages

            var search_filter         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_filter  :  payload.search_filter || ' ';
            var search_string         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_string  :  payload.search_string || ' ';
            var filtre                = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.filtre         :  payload.filtre        || '';
            var sort                  = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.sort           :  payload.sort          ;
            var startofresultreturn   = (doesloadedexist && isonlyload) ? state.loadedpages[page].startofresultreturn    : 0;
            var ids_seen              = (doesloadedexist && isonlyload) ? state.loadedpages[page]['data'].map( i=> i._id)    : [];
            var isopen                = (doesloadedexist && isonlyload) ? state.loadedpages[page]['isopen']    : payload.isopen || 0;


            // console.log('search item satis ')




            const reqsend = { 
                accountid               : state.selected_company._id,
                account_no              : state.selected_company.no,
                account_name            : state.selected_company.name,
                search_string           : search_string,
                filtre                  : filtre,
                sort                    : sort,
                startofresultreturn     : startofresultreturn,
                ids_seen                : ids_seen,
                domain                  : state.domain,
                search_filter           : search_filter,
                account_number          : state.selected_company.no,
                isopen                  : isopen,
                account_rules           : state.selected_company.rules,
                user                    : state.satis_account.user,
                username                : state.satis_account.name, 
            }

            //console.dir(reqsend)

            await fetch( using_urls[page], {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {   
                    

                    //console.dir(data.data)
                    
                const commitreq = {
                    reqsent             : reqsend,
                    data                : data.data,
                    isonlyload          : isonlyload,
                    page                : page,
                    startofresultreturn : startofresultreturn + numofreturn,
                    isopen              : isopen
                }


                commit("loadmorepagesmut", commitreq) 
                //dispatch('req_detailed_faves')
                })
                .catch((error) => {
                console.error('Error:', error);
                });            

        },
        async getinvoicedetail( {state} , payload) {

            const reqsend = {
                domain  : state.domain,
                docno   : payload.docno
            }

            const ret = await fetch( using_urls.invoice_details, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    return data.data
                })
                .catch( error => console.error('Error:', error));
                
                return ret 
        },


        // ! SATIS CART 
        async get_satis_cart_m( {state, commit, dispatch}, payload)
        {

            const reqsend = {
                user                : state['satis_account']['user'],
                name                : state['satis_account']['name'],
                domain              : state['satis_account']['domain'],
                user_id             : state['satis_account']['_id'],
                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,
            }


            await fetch( using_urls.satis_get_cart, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                dispatch('satis_get_latest_cart_details',data.data)
                commit('save_satis_cart', data.data)
            })
            .catch( error => console.error('Error:', error));
        },
        async get_satis_cust_cart_m( {state, commit, dispatch}, payload)
        {


            const reqsend = {
                user                : state['satis_account']['user'],
                name                : state['satis_account']['name'],
                domain              : state['satis_account']['domain'],
                user_id             : state['satis_account']['_id'],
                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,
            }
            await fetch( using_urls.satis_get_cust_cart, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                commit('save_satis_cust_cart', data.data)
                dispatch('satis_get_latest_cart_details',data.data)
            })
            .catch( error => console.error('Error:', error));
        },
        
        async get_product_with_images( {state }, payload){
            const remainingImagesId = payload.remainingImagesId
            return remainingImagesId
        },

        async act_get_satis_cust_cari( {state, commit, dispatch}, payload)
        {

            // const isonlyload            = payload.isonlyload
            const page                  = 'satis_get_cust_cari'
            // const numofreturn           = 25

            // const doesloadedexist       = page in state.loadedpages

            // var filtre                = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.filtre         :  payload.filtre        || '';
            // var sort                  = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.sort           :  payload.sort          ;
            // var startofresultreturn   = (doesloadedexist && isonlyload) ? state.loadedpages[page].startofresultreturn    : 0;
            // var ids_seen              = (doesloadedexist && isonlyload) ? state.loadedpages[page]['data'].map( i=> i._id)    : [];
            var isopen                =  0;
            // var isopen                = (doesloadedexist && isonlyload) ? state.loadedpages[page]['isopen']    : payload.isopen || 0;
            
            const reqsend = { 

                // user                : state['satis_account']['user'],
                // name                : state['satis_account']['name'],
                domain              : state['satis_account']['domain'],
                // user_id             : state['satis_account']['_id'],
                // selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,

                // filtre              : filtre,
                // sort                : sort,
                // ids_seen            : ids_seen,
                isopen              : isopen,

            }

            // console.dir(reqsend)

            var ret_data = []

            await fetch( using_urls[page], {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {   
                    
                    // console.dir(data.data)
                    
                    // const commitreq = {
                    //     reqsent             : reqsend,
                    //     data                : data.data,
                    //     isonlyload          : isonlyload,
                    //     page                : page,
                    //     startofresultreturn : startofresultreturn + numofreturn,
                    //     isopen              : isopen
                    // }

                    // commit("loadmorepagesmut", commitreq)

                    dispatch("satis_get_fast_cari")

                    ret_data = data.data //28.02.2024 eklendi

                    //dispatch('req_detailed_faves')
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            return ret_data
        }, 


        // async act_get_satis_get_not_closed( {state, commit, dispatch}, payload)
        // {

        //     // const isonlyload            = payload.isonlyload
        //     const page                  = 'satis_get_cust_cari'
        //     // const numofreturn           = 25

        //     // const doesloadedexist       = page in state.loadedpages

        //     // var filtre                = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.filtre         :  payload.filtre        || '';
        //     // var sort                  = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.sort           :  payload.sort          ;
        //     // var startofresultreturn   = (doesloadedexist && isonlyload) ? state.loadedpages[page].startofresultreturn    : 0;
        //     // var ids_seen              = (doesloadedexist && isonlyload) ? state.loadedpages[page]['data'].map( i=> i._id)    : [];
        //     var isopen                = 1
            
        //     const reqsend = { 
        //         // user                : state['satis_account']['user'],
        //         // name                : state['satis_account']['name'],
        //         domain              : state['satis_account']['domain'],
        //         // user_id             : state['satis_account']['_id'],
        //         selected_company_id : state.selected_company._id,
        //         // selected_company_no : state.selected_company.no,

        //         // filtre              : filtre,
        //         // sort                : sort,
        //         // ids_seen            : ids_seen,
        //         isopen              : isopen,
        //     }

        //     //console.dir(reqsend)

        //     await fetch( using_urls[page], {
        //         method: 'POST', // or 'PUT'
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(reqsend),
        //         })
        //         .then(response => response.json())
        //         .then(data => {   
                    
        //             //console.dir(data.data)
                    
        //             // const commitreq = {
        //             //     reqsent             : reqsend,
        //             //     data                : data.data,
        //             //     isonlyload          : isonlyload,
        //             //     page                : page,
        //             //     startofresultreturn : startofresultreturn + numofreturn,
        //             //     isopen              : isopen
        //             // }

        //             // commit("loadmorepagesmut", commitreq)

        //             // dispatch("satis_get_fast_cari")

        //             return data.data //28.02.2024 eklendi

        //             //dispatch('req_detailed_faves')
        //         })
        //         .catch((error) => {
        //         console.error('Error:', error);
        //         });
        // },

        async act_get_satis_islem( {state, commit, dispatch}, payload)
        {

            const send_req = {
                user                : state['satis_account']['user'],
                name                : state['satis_account']['name'],
                domain              : state['satis_account']['domain'],
                user_id             : state['satis_account']['_id'],
                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,
            }

            console.dir(send_req)

            const isonlyload            =  payload.isonlyload
            const page                  = 'satis_islem_listesi'
            const numofreturn           = 25

            const doesloadedexist       = page in state.loadedpages

            var search_filter         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_filter  :  payload.search_filter || ' ';
            var search_string         = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.search_string  :  payload.search_string || ' ';
            var filtre                = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.filtre         :  payload.filtre        || '';
            var sort                  = (doesloadedexist && isonlyload) ? state.loadedpages[page].reqsent.sort           :  payload.sort          ;
            var startofresultreturn   = (doesloadedexist && isonlyload) ? state.loadedpages[page].startofresultreturn    : 0;
            var ids_seen              = (doesloadedexist && isonlyload) ? state.loadedpages[page]['data'].map( i=> i._id)    : [];
            var isopen                = 1
            
            const reqsend = { 

                user                : state['satis_account']['user'],
                name                : state['satis_account']['name'],
                domain              : state['satis_account']['domain'],
                user_id             : state['satis_account']['_id'],
                selected_company_id : state.selected_company._id,
                selected_company_no : state.selected_company.no,

                filtre              : filtre,
                sort                : sort,
                ids_seen            : ids_seen,
                isopen              : isopen,

            }

            // console.log(reqsend)

            await fetch( using_urls[page], {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {   
                    

                    //console.dir(data.data)
                    
                const commitreq = {
                    reqsent             : reqsend,
                    data                : data.data,
                    isonlyload          : isonlyload,
                    page                : page,
                    startofresultreturn : startofresultreturn + numofreturn,
                    isopen              : isopen
                }

                // console.log(commitreq)
                commit("loadmorepagesmut", commitreq)

                //dispatch('req_detailed_faves')
                })
                .catch((error) => {
                console.error('Error:', error);
                });
        },

        async get_fast_cari({state,commit}, payload)
        {

            const reqsend = {
                acc_email : state.accountinfo.no,
                domain    : state.domain
            }

            await fetch( using_urls.get_fast_cari, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // console.dir(data)
                commit('save_fast_cari', data)
            })
            .catch( error => console.error('Error:', error));


        },

        async satis_get_fast_cari({state,commit}, payload)
        {

            const reqsend = {
                acc_email : state.selected_company.no,
                domain    : state['satis_account']['domain'],
            }

            await fetch( using_urls.get_fast_cari, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // console.dir(data)
                commit('save_satis_fast_cari', data)
            })
            .catch( error => console.error('Error:', error));


        },

        async reset_state_1({state, commit}, payload)
        {
            commit('reset_state',{})
        },
        //================ UPDATE CART DISPATCHER ================
        //
        //
        //
        //========================================================
        async commit_cartinfo( {commit}, payload) {
            commit('updatecart',payload)
        },
        async commit_returncartinfo( {commit}, payload) {
            commit('updatereturncart',payload)
        },
        async commit_cartchanges( {commit}, payload){
            commit('update_cart_change_payload', payload)
        },
        async commit_bank_pos_info( {commit}, payload)
        {
            commit('save_bank_pos_info',payload)
        },
        async commit_domain_info( {commit}, payload)
        {
            commit('save_domain_info', payload)
        },
        async commit_arsal_vendor_info( {commit}, payload)
        {
            commit('save_arsal_vendor_info', payload)
        },
        async commit_loginsuccess( { commit}, payload)
        {
            commit('save_loginsuccess',payload)
        },
        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        async commit_satisloginsuccess( { commit}, payload)
        {
            commit('save_satisloginsuccess',payload)
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        async commit_settings_data( {commit}, payload)
        {
            commit('save_settings' , payload)
        },
        async commit_salesperson_data( {commit}, payload)
        {
            commit('save_salesperson' , payload)
        },
        async commit_user_searchpool( {commit}, payload)
        {
            commit('save_user_searchpool', payload)
        },
        async commit_outofdate_total( {commit}, payload)
        {
            commit('save_outofdate_total', payload)
        },
        async commit_outofdate_total_info( {commit}, payload)
        {
            commit('save_outofdate_total_info', payload)
        },
        async set_dash_active_table( {commit}, payload)
        {
            commit('save_dash_active_table', payload.active_table)
        },
        async set_satis_dash_active_table( {commit}, payload)
        {
            commit('save_satis_dash_active_table', payload.active_table)
        },
        async commit_order_no ( {commit}, payload)
        {
            commit('save_order_no', payload)
        },
        async commit_payment_installment ( {commit}, payload)
        {
            commit('save_payment_installment', payload)
        },

        async commit_reset_selected_company( {commit}, payload)
        {   

            commit('save_reset_selected_company')

        },

        async commit_cust_cari_vade({commit}, payload)
        {   
            commit('save_cust_cari_vade',payload)
        },
        async commit_cust_cari_total({commit},payload)
        {
            // console.log('total cari -=---- ' + payload)
            commit('save_cust_cari_total', payload)       
        },

        async act_get_pages({state,commit},payload)
        {
            // console.log('act_get_pages ====== ')

            const reqsend = {}

            

            await fetch( using_urls.get_settings_pages, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // console.dir(data)
                commit('save_settings_pages', data)
            })
            .catch( error => console.error('Error:', error));
        }
    },

    plugins: [createPersistedState()],
})

function getDate_yyyymmdd() {

    const date = new Date();

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${yyyy}${mm}${dd}`
}